import axios from 'axios'
import { keycloakUrl, realm, keycloakClientId } from '$api'
import { retrieveTokens, storeTokens, clearTokens } from '$api/tokenManagement'

export async function login(credentialsObj) {
  let myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

  let urlencoded = new URLSearchParams()
  urlencoded.append('client_id', keycloakClientId)
  urlencoded.append('username', credentialsObj.username)
  urlencoded.append('password', credentialsObj.password)
  urlencoded.append('grant_type', 'password')
  urlencoded.append('scope', 'openid')

  const response = await axios.post(
    `${keycloakUrl}/realms/${realm}/protocol/openid-connect/token`,
    urlencoded,
    {
      headers: myHeaders,
    },
  )
  storeTokens(
    response.data.id_token,
    response.data.access_token,
    response.data.refresh_token,
  )

  return response.data
}

export async function logout() {
  const { refreshToken } = retrieveTokens()

  let myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

  let urlencoded = new URLSearchParams()
  urlencoded.append('client_id', keycloakClientId)
  urlencoded.append('refresh_token', refreshToken)

  await axios.post(
    `${keycloakUrl.slice(0, -5)}/realms/${realm}/protocol/openid-connect/logout`,
    urlencoded,
    {
      headers: myHeaders,
    },
  )

  clearTokens()
}

export async function refreshAccessToken() {
  const { refreshToken } = retrieveTokens()

  let myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

  let urlencoded = new URLSearchParams()
  urlencoded.append('client_id', keycloakClientId)
  urlencoded.append('grant_type', 'refresh_token')
  urlencoded.append('refresh_token', refreshToken)

  const response = await axios.post(
    `${keycloakUrl}/realms/${realm}/protocol/openid-connect/token`,
    urlencoded,
    {
      headers: myHeaders,
    },
  )

  storeTokens(
    response.data.id_token,
    response.data.access_token,
    response.data.refresh_token,
  )

  return response.data.access_token
}

export async function impersonateUser(userId) {
  const { accesstoken: subjectToken } = retrieveTokens()

  let myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

  let urlencoded = new URLSearchParams()

  // settings
  urlencoded.append('client_id', keycloakClientId)
  urlencoded.append(
    'grant_type',
    'urn:ietf:params:oauth:grant-type:token-exchange',
  )
  urlencoded.append(
    'requested_token_type',
    'urn:ietf:params:oauth:token-type:refresh_token',
  )
  urlencoded.append(
    'subject_token_type',
    'urn:ietf:params:oauth:token-type:access_token',
  )
  urlencoded.append('scope', 'openid')

  // id of user to impersonate
  urlencoded.append('requested_subject', userId)
  // token whith impersonation rights
  urlencoded.append('subject_token', subjectToken)

  const response = await axios.post(
    `${keycloakUrl}/realms/${realm}/protocol/openid-connect/token`,
    urlencoded,
    {
      headers: myHeaders,
    },
  )
  return response.data
}
