import React, { useContext, useEffect } from 'react'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { Button, Input, Popconfirm, Checkbox, List, App, Segmented } from 'antd'
import {
  fetchUser,
  fetchCompanyOfUser,
  fetchUserRole,
  postUser,
  putUser,
  deleteUser,
  postRoleMapping,
  deleteRoleMapping,
  fetchAllRoles,
} from '$api/evoAPIs'
import { StyledCard } from '$globalStyledComponents/Card'
import { AuthContext } from '$context/AuthContextProvider'
import {
  extractServiceUserRole,
  keycloakToHumanreadable,
  getRoleColorByName,
} from './utils'
import Form from '$components/Form'

const useUserWithCompanyAndRoleQuery = (userId, authContext) => {
  // get selected user
  const userQuery = useQuery(['user', userId], fetchUser, {
    enabled: !!userId && authContext.isSuperadmin(),
  })

  // get users company
  const userCompanyQuery = useQuery(
    ['usercompany', userId],
    fetchCompanyOfUser,
    {
      enabled: !!userId && authContext.isSuperadmin(),
      select: (userCompanies) => userCompanies[0].name,
    },
  )

  // get users role
  const userRoleQuery = useQuery(['userRole', userId], fetchUserRole, {
    enabled: !!userId && authContext.isSuperadmin(),
    select: (userRoles) => extractServiceUserRole(userRoles) || '-',
  })

  if (userQuery.data && userCompanyQuery.data && userRoleQuery.data) {
    return {
      ...userQuery,
      data: {
        ...userQuery.data,
        company: userCompanyQuery.data,
        role: userRoleQuery.data,
      },
    }
  } else if (!authContext.isSuperadmin()) {
    return {
      ...userQuery,
      data: authContext.user,
    }
  }

  return {
    ...userQuery,
    data: undefined,
  }
}

const Userform = (props) => {
  const authContext = useContext(AuthContext)
  const formRef = React.useRef(null)
  const { notification } = App.useApp()

  // Queries
  const queryClient = useQueryClient()

  // get selected user
  const { data: user } = useUserWithCompanyAndRoleQuery(
    props.userId,
    authContext,
  )

  // fetch all roles to know role ids for potential update operations
  const { data: roles } = useQuery(['roles'], fetchAllRoles, {
    enabled: authContext.isSuperadmin(),
    select: (allRoles) => ({
      serviceuser_superadmin: allRoles.find(
        (role) => role.name === 'serviceuser_superadmin',
      ),
      serviceuser_admin: allRoles.find(
        (role) => role.name === 'serviceuser_admin',
      ),
      serviceuser_user: allRoles.find(
        (role) => role.name === 'serviceuser_user',
      ),
    }),
  })

  // Mutations
  const createUserMutation = useMutation({
    mutationFn: postUser,
    onSuccess: (user) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['companyMembers'] })

      props.setUserId(user.id)
    },
  })

  const putUserMutation = useMutation({
    mutationFn: putUser,
  })

  const deleteUserMutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ['companyMembers'],
      })
      props.setUserId(authContext.user.id)

      openNotification('Successfully deleted user!')
    },
  })

  const postRoleMappingMutation = useMutation({
    mutationFn: postRoleMapping,
  })

  const deleteRoleMappingMutation = useMutation({
    mutationFn: deleteRoleMapping,
  })

  // Helper Functions

  /*
   * update to new role by adding new one and then deleting previous one
   */
  const updateRoleMapping = (currentRole, newRole) => {
    // check if role names are valid
    if (
      currentRole in keycloakToHumanreadable &&
      newRole in keycloakToHumanreadable &&
      roles
    ) {
      // assign selected user-role
      return [
        postRoleMappingMutation.mutateAsync({
          userId: props.userId,
          roleId: roles[newRole].id,
          roleName: newRole,
        }),
        deleteRoleMappingMutation.mutateAsync({
          userId: props.userId,
          roleId: roles[currentRole].id,
          roleName: currentRole,
        }),
      ]
    }

    return []
  }

  const openNotification = (msg) => {
    notification.success({
      message: 'Success',
      description: msg,
      onClick: () => {},
      placement: 'top',
    })
  }

  const onReset = () => {
    formRef.current?.resetFields()
  }

  const onDelete = () => {
    if (props.newUser === true) {
      formRef.current?.resetFields()
    } else if (user) {
      deleteUserMutation.mutate({
        userId: user.id,
      })
    }
  }

  const onFinish = (values) => {
    if (roles) {
      if (props.newUser === true) {
        // create a new user
        createUserMutation
          .mutateAsync({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            username: values.username,
            company: values.company,
            password: values.password,
          })
          .then((user) => {
            // assign role to new User
            postRoleMappingMutation
              .mutateAsync({
                userId: user.id,
                roleId: roles[values.role].id,
                roleName: values.role,
              })
              .then(() => {
                openNotification('Successfully created user!')
                props.setNewUser(false)
              })
          })
      } else if (user) {
        // update selected user
        let userUpdatePromises = [
          putUserMutation.mutateAsync({
            userId: props.userId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          }),
        ]
        if (user.role !== values.role) {
          userUpdatePromises.concat(updateRoleMapping(user.role, values.role))
        }

        Promise.all(userUpdatePromises)
          .then(() => {
            queryClient.invalidateQueries({ queryKey: ['user', props.userId] })
            queryClient.invalidateQueries({
              queryKey: ['userRole', props.userId],
            })
            queryClient.invalidateQueries({ queryKey: ['companyMembers'] })
            openNotification('Successfully updated user!')
          })
          .finally(() => {
            if (props.userId === authContext.user.id) {
              queryClient.invalidateQueries({
                queryKey: ['loggedInUser'],
              })
            }
          })
      }
    }
  }

  const adminrights = [
    {
      title: 'view Parts',
      checked: true,
    },
    {
      title: 'add/edit Parts',
      checked: true,
    },
    {
      title: 'view Services',
      checked: true,
    },
    {
      title: 'book Services',
      checked: true,
    },
    {
      title: 'view Users',
      checked: true,
    },
    {
      title: 'add/edit Users',
      checked: false,
    },
    {
      title: 'view Company',
      checked: true,
    },
    {
      title: 'edit Company',
      checked: false,
    },
  ]
  const userrights = [
    {
      title: 'view Parts',
      checked: true,
    },
    {
      title: 'add/edit Parts',
      checked: false,
    },
    {
      title: 'view Services',
      checked: true,
    },
    {
      title: 'book Services',
      checked: false,
    },
    {
      title: 'view Users',
      checked: false,
    },
    {
      title: 'add/edit Users',
      checked: false,
    },
    {
      title: 'view Company',
      checked: false,
    },
    {
      title: 'edit Company',
      checked: false,
    },
  ]

  useEffect(() => {
    if (user) {
      formRef.current?.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        username: user.username,
        company: user.company,
        role: user.role,
      })
    }
  }, [user])

  useEffect(() => {
    if (props.newUser) {
      formRef.current?.setFieldsValue({
        firstName: null,
        lastName: null,
        email: null,
        username: null,
        // company: null, // company is same for all users and should not be changed
        role: 'serviceuser_user',
      })
    }
  }, [props.newUser])

  return (
    <StyledCard>
      <h3>User Data</h3>
      <br /> {/* <- shame on me ;) */}
      {!user ? (
        <> Loading... </>
      ) : (
        <Form
          name="complex-form"
          ref={formRef}
          onFinish={onFinish}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 1000 }}
          requiredMark={false}
        >
          <Form.Item
            label="First / Last Name"
            style={{ marginBottom: 0, width: '100.8%' }}
            required
          >
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: 'first name is required' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input
                placeholder="Input First Name"
                disabled={!authContext.isSuperadmin()}
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: 'last name is required' }]}
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                margin: '0 8px',
              }}
            >
              <Input
                placeholder="Input Last Name"
                disabled={!authContext.isSuperadmin()}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="company"
            label="Company"
            rules={[{ required: true, message: 'company name is required' }]}
          >
            <Input placeholder="Input Company" disabled />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input
              placeholder="Input E-Mail"
              disabled={!authContext.isSuperadmin()}
            />
          </Form.Item>
          <Form.Item
            name="username"
            label="User Name"
            rules={[{ required: true, message: 'user name is required' }]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder="Input User Name"
              disabled={!props.newUser}
            />
          </Form.Item>
          {props.newUser ? (
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: 'password is required' }]}
            >
              <Input.Password
                style={{ width: '100%' }}
                placeholder="input password"
              />
            </Form.Item>
          ) : (
            <></>
          )}
          <Form.Item name="role" label="Role" style={{ marginBottom: 15 }}>
            <Segmented
              block
              disabled={!authContext.isSuperadmin()}
              options={[
                {
                  label: (
                    <List.Item style={{ padding: '0px 0px' }}>
                      <List
                        dataSource={adminrights}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={<Checkbox checked disabled />}
                              description={item.title}
                            />
                          </List.Item>
                        )}
                      />
                    </List.Item>
                  ),
                  value: 'serviceuser_superadmin',
                  icon: (
                    <div
                      style={{
                        height: '34px',
                        alignItems: 'center',
                        display: 'inline-flex',
                        padding: '28px 22px 22px',
                      }}
                    >
                      {getRoleColorByName('serviceuser_superadmin')}
                    </div>
                  ),
                },
                {
                  label: (
                    <List.Item style={{ padding: '0px 0px' }}>
                      <List
                        dataSource={adminrights}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={
                                <Checkbox disabled checked={item.checked} />
                              }
                              description={item.title}
                            />
                          </List.Item>
                        )}
                      />
                    </List.Item>
                  ),
                  value: 'serviceuser_admin',
                  icon: (
                    <div
                      style={{
                        height: '34px',
                        alignItems: 'center',
                        display: 'inline-flex',
                        padding: '28px 22px 22px',
                      }}
                    >
                      {getRoleColorByName('serviceuser_admin')}
                    </div>
                  ),
                },
                {
                  label: (
                    <List.Item style={{ padding: '0px 0px' }}>
                      <List
                        dataSource={userrights}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={
                                <Checkbox disabled checked={item.checked} />
                              }
                              description={item.title}
                            />
                          </List.Item>
                        )}
                      />
                    </List.Item>
                  ),
                  value: 'serviceuser_user',
                  icon: (
                    <div
                      style={{
                        height: '34px',
                        alignItems: 'center',
                        display: 'inline-flex',
                        padding: '28px 22px 22px',
                      }}
                    >
                      {getRoleColorByName('serviceuser_user')}
                    </div>
                  ),
                },
              ]}
            />
          </Form.Item>
          {authContext.isSuperadmin() && (
            <Form.Item label=" " colon={false} style={{ textAlign: 'right' }}>
              <Popconfirm
                placement="top"
                title="Delete User"
                description="Are you sure to delete this user?"
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ marginRight: '10px' }}>Delete</Button>
              </Popconfirm>
              <Button onClick={onReset} style={{ marginRight: '10px' }}>
                Reset
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          )}
        </Form>
      )}
    </StyledCard>
  )
}

export default Userform
