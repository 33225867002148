import Partdetailform from './Partdetailform'

const ExpandedRow = (props) => (
  <Partdetailform
    record={props.record}
    setStatus={props.setStatus}
    setCurrentFormValues={props.setCurrentFormValues}
  />
)

export default ExpandedRow
