import { useEffect, useContext, useState } from 'react'
import {
  UserOutlined,
  SettingOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Avatar, Select, Modal, Button, Divider, theme } from 'antd'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { StyledHorizontalMenue } from '../globalStyledComponents/HorizontalMenue'
import { StyledDropdownMenue } from '../globalStyledComponents/HorizontalMenue'
import { StyledLink } from '$globalStyledComponents/Links'
import NotificationBell from './NotificationBell'
import '../assets/global-styles.less'
import Customlogo from '../assets/logos/Daimler_Buses_Logotype_1L_Black_RGB.svg'
import { AuthContext } from '../context/AuthContextProvider'
import { useTranslation } from 'react-i18next'
import { ImprintText } from '../pages/shared/Imprint'
import { LoginOutlined } from '@ant-design/icons'

const { useToken } = theme

const Topnav = (props) => {
  const authContext = useContext(AuthContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const currentPath = useLocation().pathname
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { token } = useToken()

  useEffect(() => {
    if (currentPath.substring(1)) {
      props.setCurrent(currentPath.substring(1))
    }
  }, [currentPath, props])

  const getNavItems = () => {
    let items = [
      { label: t('Marketplace'), key: 'marketplace' },
      { label: t('parts_master_data.title'), key: 'partsmasterdata' },
      // { label: t('Supply'), key: 'supply' },
      // { label: t('Monitor'), key: 'monitor' },
      // { label: t('My Materials'), key: 'mymaterials' }, // TODO: VBS-1688
      { label: t('My Services'), key: 'myservices' },
      { label: t('My Orders'), key: 'myorders' },
    ]
    if (authContext.isServiceProvider()) {
      items = [
        { label: t('ordered_services.title'), key: 'orderedservices' },
        { label: t('my_printers.title'), key: 'myprinters' },
        { label: t('received_requests.title'), key: 'receivedrequests' },
      ]
    }
    if (!authContext.isAuthenticated) {
      items = []
    }
    return items
  }

  const handleClick = (e) => {
    props.setCurrent(e.key)
    navigate('/' + e.key)
  }

  const { Option } = Select

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const settingsitems = [
    // {
    //   key: '1',
    //   label: (
    //     <Link to={'/user'} replace onClick={() => props.setCurrent('user')}>
    //       {t('user_master_data.title')}
    //     </Link>
    //   ),
    // },
    // {
    //   key: '2',
    //   label: (
    //     <Link to={'/company'} onClick={() => props.setCurrent('company')}>
    //       {t('company_master_data.title')}
    //     </Link>
    //   ),
    // },
    {
      key: '3',
      label: <div onClick={() => authContext.logout()}>{t('Logout')}</div>,
    },
  ]

  return (
    <div className="navbar-header">
      <img
        width={200}
        src={token.logo || Customlogo}
        alt="Logo of the Digital Warehouse"
        style={{ margin: '2px 10px 0 0' }}
      />
      <StyledHorizontalMenue
        onClick={handleClick}
        selectedKeys={[props.current]}
        mode="horizontal"
        items={getNavItems()}
      />
      {authContext.isAuthenticated ? <NotificationBell /> : null}
      <div className="lang-switcher">
        <Select
          defaultValue={i18n.resolvedLanguage || 'en'}
          onChange={(lang) => i18n.changeLanguage(lang)}
          variant="borderless"
        >
          <Option value="de">DE</Option>
          <Option value="en">EN</Option>
          {process.env.NODE_ENV !== 'production' && (
            <Option value="cimode">FX</Option>
          )}
        </Select>
      </div>
      {authContext.isAuthenticated ? (
        <>
          <div className="navbar-user">
            {/* <Link
              to={'/user'}
              onClick={() => props.setCurrent('user')}
              style={{ marginTop: '2px' }}
            > */}
            <Avatar icon={<UserOutlined />} style={{ margin: '6px' }} />
            {/* </Link> */}
            <span
              style={{ fontSize: '12px' }}
              className="ant-menu-title-content"
            >
              {authContext.user.firstName + ' ' + authContext.user.lastName}
            </span>
            <StyledDropdownMenue
              overlayStyle={{ width: '160px', textAlign: 'right' }}
              menu={{ items: settingsitems }}
              placement="bottomRight"
              trigger={['click']}
            >
              <SettingOutlined
                style={{
                  fontSize: '18px',
                  margin: '0 0 0 8px',
                  cursor: 'pointer',
                  padding: '22px 0px 22px',
                }}
              />
            </StyledDropdownMenue>
          </div>
          <div className="info-circle">
            <InfoCircleOutlined
              style={{ fontSize: '16px', margin: '4px 8px 0 0' }}
              onClick={showModal}
            />
          </div>
        </>
      ) : (
        <Link to={'/login'}>
          <Button type="primary" icon={<LoginOutlined />} size="large">
            {t('login')}
          </Button>
        </Link>
      )}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button type="primary" onClick={handleOk} key="okButton">
            OK
          </Button>,
        ]}
      >
        <ImprintText />
        <div>
          <StyledLink to="/imprint" onClick={handleOk}>
            {t('Provider')}
          </StyledLink>
          <Divider type="vertical" />
          <StyledLink to="/data-privacy" onClick={handleOk}>
            {t('Data protection')}
          </StyledLink>
          <Divider type="vertical" />
          <StyledLink to="/legal-information" onClick={handleOk}>
            {t('Legal notices')}
          </StyledLink>
        </div>
      </Modal>
    </div>
  )
}

export default Topnav
