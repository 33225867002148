import {
  UilAnalytics,
  UilBrushAlt,
  UilCube,
  UilFileCheck,
  UilStore,
  UilTruck,
  UilEstate,
} from '@iconscout/react-unicons'

export const SERVICES = [
  {
    key: 1,
    id: '63dcfb10f81b69c6d3a7c431',
    icon: <UilFileCheck size="21" color="#F1B44C" style={{ margin: '-5px' }} />,
    servicename: 'service.feasibility_check.name',
    serviceInfo: 'service.feasibility_check.service_description',
  },
  {
    key: 2,
    id: 'main',
    icon: <UilEstate size="21" color="#F1B44C" style={{ margin: '-5px' }} />,
    servicename: 'service.prepare_for_digital_warehousing',
    serviceInfo: 'service.prepare_for_digital_warehousing_description',
    subservices: [
      {
        key: 1,
        id: '6405eed3cebc2701ca6c4967',
        icon: <UilCube size="21" color="#F1B44C" style={{ margin: '-5px' }} />,
        servicename: 'service.generate_3d_model.name',
        serviceInfo: 'service.generate_3d_model.service_description',
      },
      {
        key: 2,
        id: '63dcfb01f81b69c6d3a7c42e',
        icon: (
          <UilBrushAlt size="21" color="#F1B44C" style={{ margin: '-5px' }} />
        ),
        servicename: 'service.apply_texturing.name',
        serviceInfo: 'service.apply_texturing.service_description',
      },
      {
        key: 3,
        id: '63dcfaf1f81b69c6d3a7c42d',
        icon: <UilStore size="21" color="#F1B44C" style={{ margin: '-5px' }} />,
        servicename: 'service.apply_branding.name',
        serviceInfo: 'service.apply_branding.service_description',
      },
    ],
  },
  {
    key: 3,
    id: '63dcfb10f81b69c6d3a7c42f',
    icon: <UilTruck size="21" color="#F1B44C" style={{ margin: '-5px' }} />,
    servicename: 'service.order_part.name',
    serviceInfo: 'service.order_part.service_description',
  },
  {
    key: 4,
    id: '63dcfb24f81b69c6d3a7c430',
    icon: <UilAnalytics size="21" color="#F1B44C" style={{ margin: '-5px' }} />,
    servicename: 'service.digital_part_report.name',
    serviceInfo: 'service.digital_part_report.service_description',
  },
]

export const SESSION_EXPIRED = 'session_expired'
export const AUTOMATIC_LOGOUT = 'automatic_logout'
export const LOGOUT_FLAGS = [SESSION_EXPIRED, AUTOMATIC_LOGOUT]

export const REDIRECT_PARAM = 'redirect'

// FEASABILITY_CHECK: { key: 1, id: "63dcfb10f81b69c6d3a7c431", name: t("Feasability Check") },
// REQUEST_AM_OFFER: { key: 2, id: "63dcfac9f81b69c6d3a7c42b", name: t("Reque") },
// GENERATE_3D_MODEL: { key: 3, id: "6405eed3cebc2701ca6c4967", name: t("Feasability Check") },
// APPLY_BRANDING: { key: 4, id: "63dcfaf1f81b69c6d3a7c42d", name: t("Feasability Check") },
// ALLPY_TEXTURING: { key: 5, id: "63dcfb01f81b69c6d3a7c42e", name: t("Feasability Check") },
// ORDER_PART: { key: 6, id: "63dcfb10f81b69c6d3a7c42f", name: t("Feasability Check") },
// DIGITAL_PARTS_REPORT: { key: 7, id: "63dcfb24f81b69c6d3a7c430", name: t("Feasability Check") }
