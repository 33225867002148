import React, { useContext, useEffect } from 'react'
import { Button, Input, Select, Popconfirm, Avatar, App } from 'antd'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { AuthContext } from '$context/AuthContextProvider'
import { fetchCompanyOfUser, fetchCompanyData } from '$api/evoAPIs'
import { evoKeycloak, realm } from '$api'
import { UserOutlined } from '@ant-design/icons'
import { StyledCard } from '$globalStyledComponents/Card'
import { StyledSegmented } from './styledComponents'
import Form from '$components/Form'

const { Option } = Select

const selectBeforePhone = (
  <Form.Item name="prefix" noStyle>
    <Select style={{ width: 70 }} placeholder="+49">
      <Option value="49">+49</Option>
      <Option value="43">+43</Option>
      <Option value="44">+44</Option>
    </Select>
  </Form.Item>
)
const selectBeforeWebsite = (
  <Select defaultValue="https://">
    <Option value="http://">http://</Option>
    <Option value="https://">https://</Option>
  </Select>
)
const memberoptions = [
  {
    label: (
      <div style={{ padding: 4, marginTop: 5 }}>
        <Avatar
          style={{ backgroundColor: '#BFBFBF' }}
          icon={<UserOutlined />}
        />
        <div>Standard</div>
      </div>
    ),
    value: 'user1',
  },
  {
    label: (
      <div style={{ padding: 4, marginTop: 5 }}>
        <Avatar.Group>
          <Avatar
            style={{ backgroundColor: '#BFBFBF' }}
            icon={<UserOutlined />}
          />
          <Avatar
            style={{ backgroundColor: '#BFBFBF' }}
            icon={<UserOutlined />}
          />
        </Avatar.Group>
        <div>Premium</div>
      </div>
    ),
    value: 'user2',
  },
  {
    label: (
      <div style={{ padding: 4, marginTop: 5 }}>
        <Avatar.Group>
          <Avatar
            style={{ backgroundColor: '#BFBFBF' }}
            icon={<UserOutlined />}
          />
          <Avatar
            style={{ backgroundColor: '#BFBFBF' }}
            icon={<UserOutlined />}
          />
          <Avatar
            style={{ backgroundColor: '#BFBFBF' }}
            icon={<UserOutlined />}
          />
        </Avatar.Group>
        <div>Gold</div>
      </div>
    ),
    value: 'user3',
  },
]

const Companyform = (props) => {
  const authContext = useContext(AuthContext)
  const queryClient = useQueryClient()
  const formRef = React.useRef(null)
  const { notification } = App.useApp()
  useQuery(['companyuser', authContext.user.id], fetchCompanyOfUser, {
    enabled: !!authContext.user.id,
    onSuccess: (response) => {
      if (typeof authContext.user.id !== 'undefined') {
        props.setCompanyID(response[0].id)
      }
    },
  })
  useQuery(['company', props.companyID], fetchCompanyData, {
    enabled: !!props.companyID,
    onSuccess: (response) => {
      if (typeof props.companyID !== 'undefined') {
        formRef.current?.setFieldsValue({
          company: response.name,
          address1: response.attributes.addressline1[0],
          address2: response.attributes.addressline2[0],
          postalcode: response.attributes.postalcode[0],
          city: response.attributes.city[0],
          state: response.attributes.state[0],
          country: response.attributes.country[0],
          email: response.attributes.email[0],
          website: response.attributes.website[0],
          phone: response.attributes.phone[0],
          vat: response.attributes.vat[0],
          membership: response.attributes.membership[0],
        })
        props.setCompany(response)
        if (response.subGroups.length > 0 && props.userCompany[0] === '') {
          props.setUserCompany([response, true])
          props.setSubCompanyCount(response.subGroups.length)
        } else if (
          response.subGroups.length === 0 &&
          props.userCompany[0] === ''
        ) {
          props.setUserCompany([response, false])
        } else if (
          response.subGroups.length > 0 &&
          props.userCompany[1] === true
        ) {
          props.setUserCompany([response, true])
        }
      }
    },
    onError: (e) => {
      console.error(e)
      openErrorNotification(
        e?.response?.status ? String(e?.response?.status) : String(e?.message),
      )
    },
  })
  const updateCompanyMutation = useMutation(
    (values) => {
      return evoKeycloak.put(
        `/admin/realms/${realm}/groups/${props.companyID}`,
        '{"name":"' +
          values.company +
          '","attributes":{"addressline1":["' +
          values.address1 +
          '"],"addressline2":["' +
          values.address2 +
          '"],"postalcode":["' +
          values.postalcode +
          '"],"city":["' +
          values.city +
          '"],"state":["' +
          values.state +
          '"],"country":["' +
          values.country +
          '"],"email":["' +
          values.email +
          '"],"website":["' +
          values.website +
          '"],"phone":["' +
          values.phone +
          '"],"vat":["' +
          values.vat +
          '"],"membership":["' +
          values.membership +
          '"]}}',
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
    },
    {
      onSuccess: () => {
        openSuccessNotification('Company updated')
        queryClient.invalidateQueries(['companyuser'])
      },
      onError: (e) => {
        console.error(e)
      },
    },
  )
  const postNewCompanyMutation = useMutation(
    (values) => {
      return evoKeycloak.post(
        `/admin/realms/${realm}/groups/${props.userCompany[0].id}/children`,
        '{"name":"' +
          values.company +
          '","attributes":{"addressline1":["' +
          values.address1 +
          '"],"addressline2":["' +
          values.address2 +
          '"],"postalcode":["' +
          values.postalcode +
          '"],"city":["' +
          values.city +
          '"],"state":["' +
          values.state +
          '"],"country":["' +
          values.country +
          '"],"email":["' +
          values.email +
          '"],"website":["' +
          values.website +
          '"],"phone":["' +
          values.phone +
          '"],"vat":["' +
          values.vat +
          '"],"membership":["' +
          values.membership +
          '"]}}',
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
    },
    {
      onSuccess: () => {
        openSuccessNotification('New Company created')
        props.setUserCompany(['', props.userCompany[1]])
        queryClient.invalidateQueries(['company'])
        props.setIsNewCompany(false)
      },
      onError: (e) => {
        console.error(e)
      },
    },
  )
  const deleteCompanyMutation = useMutation(
    async () => {
      if (props.companyID !== props.userCompany.id) {
        return evoKeycloak.delete(
          `/admin/realms/${realm}/groups/${props.companyID}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
      } else {
        throw new Error('Operation not possible')
      }
    },
    {
      onSuccess: () => {
        openSuccessNotification('Company deleted')
        props.setUserCompany(['', props.userCompany[1]])
        queryClient.invalidateQueries(['companyuser'])
      },
      onError: (e) => {
        console.error(e)
        if (e.response.status === 403) {
          openErrorNotification(
            'Company could not be deleted, because you do not have the appropriate rights',
          )
        } else {
          openErrorNotification('Company could not be deleted')
        }
      },
    },
  )

  useEffect(() => {
    if (props.isNewCompany) {
      formRef.current?.resetFields()
    }
  }, [props.isNewCompany])

  const openSuccessNotification = (msg) => {
    notification.success({
      message: 'Success',
      description: msg,
      onClick: () => {},
      placement: 'top',
    })
  }
  const openErrorNotification = (msg) => {
    notification.error({
      message: 'Error',
      description: msg,
      onClick: () => {},
      placement: 'top',
    })
  }
  const onFinish = (values) => {
    if (props.isNewCompany) {
      if (
        values.company !==
          props.userCompany[0].subGroups.find(
            (obj) => obj.name === values.company,
          )?.name &&
        values.company !== props.userCompany[0].name
      ) {
        postNewCompanyMutation.mutate(values)
      } else {
        notification.error({
          message: 'Success',
          description:
            'The company name already exists! please choose another one.',
          onClick: () => {},
          placement: 'top',
        })
      }
    } else {
      updateCompanyMutation.mutate(values)
    }
  }
  const onReset = () => {
    formRef.current?.resetFields()
  }
  const onDelete = () => {
    deleteCompanyMutation.mutate()
  }
  return (
    <StyledCard>
      <h3>{props.isNewCompany ? 'Add new company' : 'Company Master Data'}</h3>
      <br /> {/* <- shame on me ;) */}
      <Form
        name="complex-form"
        onFinish={onFinish}
        ref={formRef}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 1000 }}
        //disabled={true}
      >
        <Form.Item name="company" label="Company Name" required>
          <Input placeholder="Input Company Name" disabled />
        </Form.Item>
        <Form.Item
          label="Address line"
          style={{ marginBottom: 0, marginLeft: -3, width: '101.1%' }}
          required
        >
          <Form.Item
            name="address1"
            rules={[{ required: true, message: 'Address is required' }]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
          >
            <Input placeholder="Input Address line 1 / Street Name / PO Box" />
          </Form.Item>
          <Form.Item
            name="address2"
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)',
              margin: '0 8px',
            }}
          >
            <Input placeholder="Input Address line 2 / Floor / Building / Unit" />
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Postal Code / City"
          style={{ marginBottom: 0, marginLeft: -6, width: '102%' }}
          required
        >
          <Form.Item
            name="postalcode"
            rules={[{ required: true, message: 'Required' }]}
            style={{ display: 'inline-block', width: 'calc(16.6% - 8px)' }}
          >
            <Input
              placeholder="Input Postal Code / ZIP / PLZ"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            name="city"
            rules={[{ required: true, message: 'City is required' }]}
            style={{
              display: 'inline-block',
              width: 'calc(33% - 8px)',
              margin: '0 8px',
            }}
          >
            <Input placeholder="Input City" />
          </Form.Item>
          <Form.Item
            name="state"
            rules={[
              {
                required: true,
                message: 'State / Province / Region is required',
              },
            ]}
            style={{
              display: 'inline-block',
              width: 'calc(33% - 8px)',
              margin: '0 8px 0 0',
            }}
          >
            <Input placeholder="Input State / Province / Region" />
          </Form.Item>
          <Form.Item
            name="country"
            rules={[{ required: true, message: 'Country is required' }]}
            style={{
              display: 'inline-block',
              width: 'calc(16.6% - 8px)',
              margin: '0px',
            }}
          >
            <Select
              placeholder="Country"
              variant="outlined"
              options={[
                { value: 'DE', label: 'DE' },
                { value: 'FR', label: 'FR' },
                { value: 'US', label: 'US' },
                { value: 'CN', label: 'CN', disabled: true },
              ]}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="E-Mail / Website"
          style={{ marginBottom: 0, marginLeft: -3, width: '101.1%' }}
          required
        >
          <Form.Item
            name="email"
            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input placeholder="Input E-Mail Address" />
          </Form.Item>
          <Form.Item
            name="website"
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)',
              margin: '0 8px',
            }}
          >
            <Input
              addonBefore={selectBeforeWebsite}
              placeholder="Input Website URL"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[
            { required: true, message: 'Please input your phone number!' },
          ]}
        >
          <Input
            addonBefore={selectBeforePhone}
            style={{ width: '100%' }}
            placeholder="Input Phone Number"
          />
        </Form.Item>
        <Form.Item name="vat" label="VAT ID Number">
          <Input placeholder="Input VAT ID / Ust-ID" />
        </Form.Item>
        <Form.Item
          name="membership"
          label="Membership"
          style={{ marginBottom: 15 }}
        >
          <StyledSegmented
            options={memberoptions}
            style={{ width: '100%' }}
            disabled={true}
          />
        </Form.Item>
        <Form.Item label=" " colon={false} style={{ textAlign: 'right' }}>
          <Popconfirm
            placement="top"
            title="Delete Company"
            description={
              props.companyID !== props.userCompany.id
                ? `sure you want to delete company ${props.company.name} permanently?`
                : 'you can not delete your main company!'
            }
            onConfirm={onDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button style={{ marginRight: '10px' }}>Delete</Button>
          </Popconfirm>
          <Button onClick={onReset} style={{ marginRight: '10px' }}>
            Reset
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </StyledCard>
  )
}
export default Companyform
