import { AuthContext } from '$context/AuthContextProvider'
import { ShoppingCartOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Row } from 'antd'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { fetchAllOrderPartsLists } from '$api/evoAPIs'
import { Link } from 'react-router-dom'

const Topwelcomecard = () => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)

  const { data } = useQuery(['allorderedpartslists'], fetchAllOrderPartsLists, {
    placeholderData: [],
    select: (data) => ({
      totalOrders: data.length,
      acceptedOrders: data.filter((order) => order.client_accepted_offer)
        .length,
      pendingOrders: data.filter(
        // if accepted_offer is not set (neither true nor false) consider pending
        (order) => order.client_accepted_offer == null,
      ).length,
    }),
  })

  const { totalOrders = '-', acceptedOrders = '-', pendingOrders = '-' } = data

  return (
    <>
      <Row className="myevocard">
        <Col span={8}>
          <Row>
            <Col flex="100px">
              <Avatar size={64} icon={<UserOutlined />} />
            </Col>
            <Col flex="auto">
              {t('my_orders.welcome_text')}
              <h2>
                {authContext.user.firstName + ' ' + authContext.user.lastName}
              </h2>
            </Col>
          </Row>
        </Col>
        <Col
          span={8}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            textAlign: 'center',
          }}
        >
          <Col style={{ width: '50%' }}>
            {t('my_orders.total_orders')}
            <h2>{totalOrders}</h2>
          </Col>
          <Col style={{ width: '50%' }}>
            {t('my_orders.accepted_orders')}
            <h2>{acceptedOrders}</h2>
          </Col>
          <Col style={{ width: '50%' }}>
            {t('my_orders.pending_orders')}
            <h2>{pendingOrders}</h2>
          </Col>
        </Col>
        <Col span={8} style={{ textAlign: 'right', margin: 'auto' }}>
          <div style={{ display: 'inline-block' }}>
            <Link to="/marketplace">
              <Button
                type="primary"
                icon={<ShoppingCartOutlined />}
                size="large"
              >
                {t('my_orders.order_new_parts')}
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Topwelcomecard
