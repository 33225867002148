import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { App, Button, Popconfirm } from 'antd'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { evoConsolidation } from '$api'
import { evoClient, putPart } from '$api/client'
import { fetchAllServices, postFcService } from '$api/evoAPIs'
import { AuthContext } from '$context/AuthContextProvider'
import { updateStatusAttributes } from '$utils'
import StatusPage from '../Defaultwizard/StatusPage'
import StepOneFeasibilitySelection from './StepOneFeasibilitySelection'
import StepThreeSummary from '../Defaultwizard/StepThreeSummary'
import {
  StyledStepButtons,
  StyledSteps,
} from '../Defaultwizard/styledComponents'

const FeasibilityCheck = (props) => {
  const { t } = useTranslation()
  const [fcData] = useState({
    not_feasible_parts: [],
    feasible_parts: [],
  })
  const [bookableParts, setBookableParts] = useState()
  const [steps, setSteps] = useState([
    {
      title: t('service.selection'),
      content: '',
    },
    {
      title: t('service.order'),
      content: '',
    },
  ])
  const [current, setCurrent] = useState(0)
  const { message } = App.useApp()
  const [visible, setVisible] = useState(true)
  const [loading, setLoading] = useState(false)
  const [buttonActive, setButtonActive] = useState(true)
  const [configState, setConfigState] = useState(true)
  const queryClient = useQueryClient()
  const authContext = useContext(AuthContext)
  const { data: servicesData } = useQuery(['services'], fetchAllServices)

  const addBookedFeasibilityChecks = useMutation(
    ({ key: _key, id, ...bookablePart }) => {
      // construct an object that matches api
      const part = {
        ...bookablePart,
        id: id,
        db_id_client: id,
        files: [],
      }

      const feasibilitycheckbody = {
        service_id: (
          servicesData.find((x) => x.name === 'Feasibility Check') || {}
        ).id,
        part: part,
        partlist_name: props.activeGroupName,
      }
      return evoConsolidation.post(postFcService, feasibilitycheckbody, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    {
      onSuccess: (data) => {
        setLoading(false)
        updateClientPartStatus.mutate(data.data)
        //message.success("Booked Service added")
        const arrOfNotUpdatedIds = localStorage.getItem('notupdatedfc')
          ? JSON.parse(localStorage.getItem('notupdatedfc'))
          : []

        if (arrOfNotUpdatedIds && arrOfNotUpdatedIds.length > 0) {
          arrOfNotUpdatedIds.push(data.data.id)
          localStorage.setItem(
            'notupdatedfc',
            JSON.stringify(arrOfNotUpdatedIds),
          )
        } else {
          const idArr = [data.data.id]
          localStorage.setItem('notupdatedfc', JSON.stringify(idArr))
        }
      },
      onError: (err) => {
        message.error(
          err.response.status + ', Could not post to feasibility endpoint',
        )
        setLoading(false)
      },
    },
  )

  const updateClientPartStatus = useMutation(
    (partsToUpdate) => {
      if (partsToUpdate.part) {
        const editedPart = updateStatusAttributes(
          partsToUpdate.part,
          'status_attributes',
          'Feasibility Check',
          '1',
        )
        editedPart.owner = authContext.user.username
        editedPart.png = 'nopng'
        return evoClient.put(
          `${putPart}${editedPart.db_id_client}`,
          editedPart,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['allgroups'])
        queryClient.invalidateQueries(['notifications'])
        setLoading(false)
      },
      onError: (err) => {
        message.error(`${err.response.status} Could not update status`)
      },
    },
  )

  // Functions

  const updateSteps = useCallback(() => {
    if (bookableParts || servicesData) {
      const newSteps = [
        {
          title: t('service.selection'),
          content: (
            <StepOneFeasibilitySelection
              fcData={fcData}
              selectedParts={props.selectedParts}
              setBookableParts={setBookableParts}
              bookableParts={bookableParts}
              servicesData={servicesData}
              bookedServiceStatus={props.bookedServiceStatus}
              setConfigState={setConfigState}
            />
          ),
        },
        {
          title: t('service.order'),
          content: (
            <StepThreeSummary
              fcData={fcData}
              selectedParts={props.selectedParts}
              bookableParts={bookableParts}
              setButtonActive={setButtonActive}
              bookedServiceStatus={props.bookedServiceStatus}
            />
          ),
        },
      ]
      setSteps(newSteps)
    }
  }, [
    bookableParts,
    servicesData,
    fcData,
    props.selectedParts,
    setBookableParts,
    setButtonActive,
    props.bookedServiceStatus,
    t,
  ])

  const handleOk = () => {
    setCurrent(0)
    props.setSelectedParts([])
    setButtonActive(true)

    //post to endpoint
    for (let bookablePart of bookableParts.bookableParts) {
      if (props.bookedServiceStatus[1].key === 1) {
        addBookedFeasibilityChecks.mutate(bookablePart)
      }
    }
    setLoading(true)
    setVisible(false)
  }

  const next = () => {
    if (current === 0) {
      setConfigState(false)
    }
    setCurrent(current + 1)
  }
  const prev = () => {
    if (current === 1) {
      setConfigState(true)
    }
    setCurrent(current - 1)
  }
  const items = steps
    .filter(function (x) {
      return x !== null
    })
    .map((item) => ({
      key: item.title,
      title: item.title,
    }))

  useEffect(() => {
    updateSteps()
  }, [updateSteps])

  return (
    <>
      {visible && (
        <>
          <h1 style={{ margin: '50px 0' }}>
            {t(props.bookedServiceStatus[1].servicename)}
          </h1>
          <div className="contentcard">
            <StyledSteps current={current} items={items} type="navigation" />
            <div style={{ margin: '50px 0px' }}>{steps[current].content}</div>
            <StyledStepButtons>
              {current < steps.length - 1 && (
                <Popconfirm
                  disabled={configState}
                  placement="topRight"
                  description={
                    <>
                      Some parts need configuration before proceeding.
                      <br />
                      Check the <i>status</i> of your part.
                    </>
                  }
                  title={<h4>Some changes have to be made</h4>}
                >
                  <Button
                    type="primary"
                    onClick={() => next()}
                    disabled={!configState}
                  >
                    {t('button.next')}
                  </Button>
                </Popconfirm>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => handleOk()}
                  disabled={!buttonActive}
                >
                  {t('button.order')}
                </Button>
              )}
              {current > 0 && (
                <Button
                  style={{
                    margin: '0 8px',
                  }}
                  onClick={() => prev()}
                >
                  {t('button.back')}
                </Button>
              )}
            </StyledStepButtons>
          </div>
        </>
      )}
      {!visible && (
        <>
          <StatusPage
            setIsBookModalOpen={props.setIsBookModalOpen}
            loading={loading}
            setCurrent={props.setCurrent}
          />
        </>
      )}
    </>
  )
}
export default FeasibilityCheck
