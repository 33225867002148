import { createContext, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { keycloakUrl, realm, keycloakClientId } from '$api'
import { configureClient, fetchVersion } from '$api/client'
import { fetchLoggedInUserInfo } from '$api/evoAPIs'
import {
  logout as logoutFunc,
  login as loginFunc,
  impersonateUser as impersonateFunc,
} from '$api/authentication'
import { retrieveTokens, clearTokens, storeTokens } from '$api/tokenManagement'
import {
  IncorrectCredentialsError,
  NoRoleSetError,
  MultipleRolesSetError,
  NoClientUrlSetError,
  MultipleClientUrlsSetError,
  InvalidClientUrlError,
  ClientNotReachableError,
  UpdatePasswordRequiredError,
  NoCompanySetError,
  MultipleCompaniesSetError,
} from '$constants/errors/authentication'
import ImpersonateUserSelector from './ImpersonateUserSelector'
import { LOGOUT_FLAGS, REDIRECT_PARAM } from '$constants'

/**
 * AuthContextValues defines the structure for the default values of the {@link AuthContext}.
 */

/**
 * Load data from JWT token.
 *
 * See https://stackoverflow.com/a/38552302
 */
function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

// Valid user roles for the app.
// One and only one of these must be set in Keycloak.
const VALID_USER_ROLES = [
  'serviceuser_superadmin',
  'serviceuser_admin',
  'serviceuser_user',
  'serviceprovider_superadmin',
]

// helper functions to check for type of role
const isSuperadminRole = (role) => role?.includes('superadmin')
const isServiceProviderRole = (role) => role?.includes('serviceprovider')
const isServiceUserRole = (role) => role?.includes('serviceuser')

/**
 * Helper function to extract one of the predefined roles that we use as main user role for our app.
 * This is necessary as Keycloak returns an array of roles among which is our main user role.
 * @param roles
 * @returns the users role
 * @throws NoRoleSetError, MultipleRolesSetError
 */
const extractUserRole = (roles) => {
  const assignedRoles = roles.filter((role) => VALID_USER_ROLES.includes(role))

  if (assignedRoles.length === 0) {
    throw new NoRoleSetError()
  } else if (assignedRoles.length === 1) {
    return assignedRoles[0]
  } else if (assignedRoles.length > 1) {
    throw new MultipleRolesSetError()
  }
}

/*
 * Helper functions to extract values from the keycloak object
 * obtained as response of user request or from decoding token.
 * For now always select first clientUrl and company.
 * Might implement a selection process in the future
 */
const extractClientUrl = (keycloakObject) => {
  if (!keycloakObject.client_urls?.length) {
    throw new NoClientUrlSetError()
  }

  if (keycloakObject.client_urls.length > 1) {
    throw new MultipleClientUrlsSetError()
  }

  const clientUrl = keycloakObject.client_urls[0]

  let protocol
  try {
    protocol = new URL(clientUrl).protocol
  } catch {
    throw new InvalidClientUrlError()
  }
  if (protocol !== 'http:' && protocol !== 'https:') {
    throw new InvalidClientUrlError()
  }

  return clientUrl
}

const extractThemeUrl = (keycloakObject) => {
  const themeUrl = keycloakObject.theme_url
  if (!themeUrl) {
    return
  }

  try {
    new URL(themeUrl)
  } catch {
    return
  }

  return themeUrl
}

const extractCompany = (keycloakObject) => {
  if (!keycloakObject.CompanyChain?.length) {
    throw new NoCompanySetError()
  }
  if (keycloakObject.CompanyChain.length > 1) {
    throw new MultipleCompaniesSetError()
  }
  return keycloakObject.CompanyChain[0]
}

/*
 * Extract company data provided via Custom Mapper.
 */
const extractCompanyData = (data) => ({
  name: data.CompanyChain[0].slice(1),
  addressline1: data.addressline1,
  addressline2: data.addressline2,
  postalcode: data.postalcode,
  city: data.city,
  state: data.state,
  country: data.country,
  vat: data.vat,
  website: data.website,
})

/*
 * Helper functions to check if user has impersonator role.
 * Takes ressource access roles obtained from decoded token as an argument.
 */
const hasImpersonatorRoles = (ressourceAccessRoles) => {
  const hasImpersonationRole = ressourceAccessRoles?.includes('impersonation')

  const hasViewUsersRole = ressourceAccessRoles?.includes('view-users')

  return hasImpersonationRole && hasViewUsersRole
}

/**
 * Default values for the {@link AuthContext}
 */
const defaultAuthContextValues = {
  tokenVerificationInProgress: false,
  isAuthenticated: false,
  user: '',
  bearerToken: '',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  initiateResetPasswordFlow: () => {},
  initiateUpdatePasswordFlow: () => {},
  isSuperadmin: () => false,
  isServiceProvider: () => false,
  isServiceUser: () => false,
}

/**
 * Create the AuthContext using the default values.
 */
export const AuthContext = createContext(defaultAuthContextValues)

/**
 * The props that must be passed to create the {@link AuthContextProvider}.
 */

/**
 * AuthContextProvider is responsible for managing the authentication state of the current user.
 *
 * @param props
 */
const AuthContextProvider = (props) => {
  const { i18n } = useTranslation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [bearerToken, setbearerToken] = useState(
    retrieveTokens().accesstoken || '',
  )
  const [isImpersonator, setIsImpersonator] = useState(false)
  const [usedUsername, setUsedUsername] = useState()

  /**
   * Load the profile for the user from Keycloak
   */
  const {
    data: user,
    isLoading: queryIsLoading,
    isError: queryIsError,
  } = useQuery(['loggedInUser', bearerToken], fetchLoggedInUserInfo, {
    // when token is set, verify it by querying /userinfo
    // this in turn will update isAuthenticated state
    enabled: !!bearerToken,
    cacheTime: 0,
    select: (data) => {
      const role = extractUserRole(data.realm_access.roles)

      return {
        id: data.sub,
        username: data.preferred_username,
        firstName: data.given_name,
        lastName: data.family_name,
        email: data.email,
        company: extractCompany(data),
        companyData: extractCompanyData(data),
        themeUrl: extractThemeUrl(data),
        role: role,
        clientUrl: isServiceUserRole(role) ? extractClientUrl(data) : null,
      }
    },
  })

  const { isLoading: clientIsLoading, isError: clientIsError } = useQuery(
    ['version', user?.clientUrl],
    async ({ queryKey: [_, clientUrl] }) => await fetchVersion(clientUrl),
    {
      enabled: !!user?.clientUrl,
      cacheTime: 0,
    },
  )

  const isSuperadmin = () => isSuperadminRole(user?.role)
  const isServiceProvider = () => isServiceProviderRole(user?.role)
  const isServiceUser = () =>
    isServiceUserRole(user?.role) && !isServiceProvider()

  const clientUrlIsValid = user?.clientUrl && !clientIsError

  const isAuthenticated =
    !!user &&
    !queryIsError &&
    VALID_USER_ROLES.includes(user.role) &&
    user.company &&
    (clientUrlIsValid || isServiceProvider())

  const tokenVerificationInProgress = !!(
    bearerToken &&
    queryIsLoading &&
    clientIsLoading
  )

  const login = async (credentials) => {
    let access_token
    let id_token
    setUsedUsername(credentials.username)

    try {
      ;({ id_token, access_token } = await loginFunc(credentials))
    } catch (err) {
      console.error(err)

      if (
        err instanceof AxiosError &&
        err.response &&
        err.response.data.error
      ) {
        if (err.response.status === 401) {
          // credentials are wrong
          throw new IncorrectCredentialsError()
        } else if (err.response.status === 400) {
          // credentials are correct but account is not fully setup
          throw new UpdatePasswordRequiredError()
        }
      }

      // if we couldn't handle error specifically throw original error again
      throw err
    }

    // read data contained in token
    const decodedToken = parseJwt(id_token)

    if (
      hasImpersonatorRoles(
        decodedToken?.resource_access?.['realm-management']?.roles,
      )
    ) {
      setIsImpersonator(true)
    } else {
      try {
        await verifyAccountConfig(decodedToken)
      } catch (err) {
        clearTokens()
        throw err
      }

      // finally set token to trigger userinfo request
      setbearerToken(access_token || '')
    }
  }

  const logout = async (logoutFlag, redirectPath) => {
    await logoutFunc()
    setbearerToken('')
    queryClient.removeQueries()

    let rootPath = '/login'

    const urlParams = new URLSearchParams()
    if (LOGOUT_FLAGS.includes(logoutFlag)) {
      urlParams.set(logoutFlag, '')
    }
    if (redirectPath && redirectPath !== '/') {
      urlParams.set(REDIRECT_PARAM, redirectPath)
    }
    const paramString = urlParams.toString()
    if (paramString) {
      rootPath += '?' + paramString
    }
    navigate(rootPath)
  }

  const keycloakOrigin = new URL(keycloakUrl).origin

  const initiateResetPasswordFlow = () => {
    const searchParams = new URLSearchParams({
      client_id: keycloakClientId,
      redirect_uri: window.location.origin,
      response_type: 'code',
      ui_locales: i18n.language,
    })

    // redirect to Keycloak page
    window.location.href =
      `${keycloakOrigin}/realms/${realm}/protocol/openid-connect/forgot-credentials?` +
      searchParams.toString()
  }

  const initiateUpdatePasswordFlow = () => {
    const searchParams = new URLSearchParams({
      client_id: keycloakClientId,
      redirect_uri: window.location.origin,
      response_type: 'code',
      ui_locales: i18n.language,
      scope: 'openid',
      kc_action: 'UPDATE_PASSWORD',
    })

    // redirect to Keycloak page
    window.location.href =
      `${keycloakOrigin}/realms/${realm}/protocol/openid-connect/auth?` +
      searchParams.toString()
  }

  const impersonateUser = async (userId) => {
    const { id_token, access_token, refresh_token } =
      await impersonateFunc(userId)

    const decodedIdToken = parseJwt(id_token)

    await verifyAccountConfig(decodedIdToken)
    storeTokens(id_token, access_token, refresh_token)

    setbearerToken(access_token || '')
  }

  const verifyAccountConfig = async (idtoken) => {
    // This func throws an error if user does not have any role or multiple roles
    const role = extractUserRole(idtoken.realm_access.roles)

    // throws an error if user has no company or multiple companies
    extractCompany(idtoken)

    if (isServiceUserRole(role)) {
      // throws if clientUrl is not set correctly
      const clientUrl = extractClientUrl(idtoken)

      try {
        await fetchVersion(clientUrl)
      } catch {
        throw new ClientNotReachableError()
      }
    }
  }

  if (isServiceUser()) {
    if (isAuthenticated && clientUrlIsValid) {
      configureClient(user.clientUrl)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        tokenVerificationInProgress,
        isAuthenticated,
        user: user || {
          id: '',
          firstName: '',
          lastName: '',
          email: '',
          username: '',
          company: '',
          role: '',
        },
        bearerToken,
        login,
        logout,
        initiateResetPasswordFlow,
        initiateUpdatePasswordFlow,
        isSuperadmin,
        isServiceProvider,
        isServiceUser,
      }}
    >
      {props.children}
      {isImpersonator && (
        <ImpersonateUserSelector
          impersonateUser={impersonateUser}
          loggedInAs={usedUsername}
          onCancel={() => {
            setIsImpersonator(false)
            clearTokens()
          }}
        />
      )}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
