import axios from 'axios'
import {} from 'react-router-dom'
import tokenRefreshInterceptor from '$api/tokenRefreshInterceptor'
import tokenInterceptor from '$api/tokenInterceptor'
import { attachPendingRequestsInterceptors } from './pendingRequestsInterceptor'

const consolidation =
  window.REACT_APP_EVOBUS_CONSOLIDATION ||
  process.env.REACT_APP_EVOBUS_CONSOLIDATION
const consolidationPort =
  window.REACT_APP_EVOBUS_CONSOLIDATION_PORT ||
  process.env.REACT_APP_EVOBUS_CONSOLIDATION_PORT

const keycloak =
  window.REACT_APP_EVOBUS_KEYCLOAK || process.env.REACT_APP_EVOBUS_KEYCLOAK
const keycloakPort =
  window.REACT_APP_EVOBUS_KEYCLOAK_PORT ||
  process.env.REACT_APP_EVOBUS_KEYCLOAK_PORT
export const realm =
  window.REACT_APP_KEYCLOAK_REALM || process.env.REACT_APP_KEYCLOAK_REALM
export const keycloakClientId =
  window.REACT_APP_KEYCLOAK_CLIENT_ID ||
  process.env.REACT_APP_KEYCLOAK_CLIENT_ID

export const consolidationUrl = consolidationPort
  ? `${consolidation}:${consolidationPort}`
  : consolidation

export const keycloakUrl = keycloakPort
  ? `${keycloak}:${keycloakPort}`
  : keycloak

export const evoConsolidation = axios.create({
  baseURL: consolidationUrl,
})
export const evoKeycloak = axios.create({
  baseURL: keycloakUrl,
})

// add token to all requests
evoConsolidation.interceptors.request.use(tokenInterceptor)
evoKeycloak.interceptors.request.use(tokenInterceptor)

// refresh token if response indicates it is expired
evoConsolidation.interceptors.response.use(
  (response) => response,
  tokenRefreshInterceptor(evoConsolidation),
)
evoKeycloak.interceptors.response.use(
  (response) => response,
  tokenRefreshInterceptor(evoKeycloak),
)

// track pending requests to warn user before leaving site
attachPendingRequestsInterceptors(evoConsolidation)
