import { LabelText } from '$components/Services/styledComponents'
import { AuthContext } from '$context/AuthContextProvider'
import DownloadFiles from '$components/Services/OrderedServicesDetails/DownloadFiles'
import { Col, Divider, Row } from 'antd'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const Generate3DModelDetails = ({ record }) => {
  const authContext = useContext(AuthContext)
  const { t } = useTranslation()
  return (
    <Row style={{ width: '100%' }}>
      <Col span={12} style={{ paddingRight: 30 }}>
        {record?.user_file_locations?.length > 0 && (
          <DownloadFiles record={record} style={{ marginBottom: '18px' }} />
        )}
        <LabelText>{t('bookedservices.specs_headline')}</LabelText>
        <p>{record.specifications}</p>
      </Col>
      <Col span={12}>
        {authContext?.user.role === 'serviceprovider_superadmin' ? (
          <>
            <LabelText>{t('info')}</LabelText>
            <p>{t('bookedservices.execute_service')}</p>
            <Divider />
            {t('bookedservices.generate_3d_model.the_customer_has_selected')}
          </>
        ) : (
          <LabelText>
            {t('bookedservices.generate_3d_model.selected')}
          </LabelText>
        )}
        <ul>
          {record.information_quality.map((str, index) => (
            <li key={index}>{str}</li>
          ))}
        </ul>

        {record.information_quality.includes('delivery') && (
          <>
            <p>
              {t('bookedservices.generate_3d_model.client_selected_send_part')}
            </p>
            <p>
              <b>{record.tracking_number}</b>
            </p>
            {record.send_back === true ? (
              <>
                <p>{t('bookedservices.generate_3d_model.send_back')}</p>
                <div>{record.address.name}</div>
                <div>{record.address.address_line_1}</div>
                <div>{record.address.address_line_2}</div>
                <div>
                  {record.address.postcode} {record.address.city}
                </div>
                <div>{record.address.state}</div>
                <p>{record.address.country}</p>
              </>
            ) : (
              <p>{t('bookedservices.generate_3d_model.not_send_back')}</p>
            )}
          </>
        )}
      </Col>
    </Row>
  )
}

export default Generate3DModelDetails
