import { configureClient } from '$api/client'
import {
  NoRoleSetError,
  MultipleRolesSetError,
  NoClientUrlSetError,
  MultipleClientUrlsSetError,
  InvalidClientUrlError,
  NoCompanySetError,
  MultipleCompaniesSetError,
} from '$constants/errors/authentication'

const VALID_USER_ROLES_SERVICEUSER = [
  'serviceuser_superadmin',
  'serviceuser_admin',
  'serviceuser_user',
]
const VALID_USER_ROLES_SERVICEPROVIDER = ['serviceprovider_superadmin']
const VALID_USER_ROLES = [
  ...VALID_USER_ROLES_SERVICEUSER,
  ...VALID_USER_ROLES_SERVICEPROVIDER,
]

export default class User {
  #name
  #roles
  #clientUrl
  #themeUrl
  #isServiceUser = false
  #isServiceProvider = false
  #loggedIn = true
  #onLogout = () => {}

  constructor(
    { name, clientUrls = [], companyChain, themeUrl },
    roles = [],
    onLogout = () => {},
  ) {
    this.#name = name
    this.#onLogout = onLogout
    this.roles = roles
    this.clientUrl = clientUrls[0]
    this.themeUrl = themeUrl

    // perform validations
    User.validateCompanyChain(companyChain)
  }

  get name() {
    return this.#name
  }

  get isAuthenticated() {
    return this.#loggedIn
  }

  get isSuperAdmin() {
    return false
  }

  get isServiceProvider() {
    return this.#isServiceProvider
  }

  get isServiceUser() {
    return this.#isServiceUser
  }

  set roles(assignedRoles) {
    const {
      roles: validRoles,
      isServiceProvider,
      isServiceUser,
    } = User.validateRoles(assignedRoles)

    // set properties #isServiceProvider and #isServiceUser here so we don't
    // have to re-calculate it on every call of getters isServiceProvider and
    // isServiceUser
    this.#isServiceProvider = isServiceProvider
    this.#isServiceUser = isServiceUser

    this.#roles = validRoles
  }

  get roles() {
    return this.#roles
  }

  set clientUrl(url) {
    if (url && !this.#isServiceUser) {
      console.warn(
        `The user's client URL is set to ${url} though they are not configured as a client`,
      )
    }

    configureClient(url)
    this.#clientUrl = url
  }

  get clientUrl() {
    return this.#clientUrl
  }

  set themeUrl(url) {
    if (!url) {
      return
    }

    // check if it's a valid URL
    try {
      new URL(url)
    } catch {
      return
    }

    this.#themeUrl = url
  }

  get themeUrl() {
    return this.#themeUrl
  }

  logout() {
    this.#loggedIn = false
    this.#onLogout()
  }

  static validateRoles(roles) {
    roles = roles.filter((role) => VALID_USER_ROLES.includes(role))

    if (roles.length === 0) {
      throw new NoRoleSetError()
    }

    let isServiceProvider = false
    let isServiceUser = false
    roles.forEach((role) => {
      if (VALID_USER_ROLES_SERVICEPROVIDER.includes(role)) {
        isServiceProvider = true
      }
      if (VALID_USER_ROLES_SERVICEUSER.includes(role)) {
        isServiceUser = true
      }
    })

    if (isServiceProvider && isServiceUser) {
      throw new MultipleRolesSetError()
    }

    return { isServiceProvider, isServiceUser, roles }
  }

  static extractClientUrl(clientUrls) {
    if (clientUrls.length === 0) {
      throw new NoClientUrlSetError()
    }

    if (clientUrls.length > 1) {
      throw new MultipleClientUrlsSetError()
    }

    const clientUrl = clientUrls[0]
    User.validateClientUrl(clientUrl)
    return clientUrl
  }

  static validateClientUrl(url) {
    let protocol
    try {
      protocol = new URL(url).protocol
    } catch {
      throw new InvalidClientUrlError()
    }
    if (protocol !== 'http:' && protocol !== 'https:') {
      throw new InvalidClientUrlError()
    }
    return true
  }

  static validateCompanyChain(companyChain = []) {
    if (!companyChain.length) {
      throw new NoCompanySetError()
    }
    if (companyChain.length > 1) {
      throw new MultipleCompaniesSetError()
    }
    return true
  }
}
