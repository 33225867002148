import { useContext } from 'react'
import { Navigate } from 'react-router-dom'

import { AuthContext } from '$context/AuthContextProvider'
import { REDIRECT_PARAM } from '$constants'
import HeaderRoute from '$components/HeaderRoute'
import Spinner from '$components/Spinner'

const ProtectedRoute = ({ current, setCurrent, intendedPath }) => {
  const authContext = useContext(AuthContext)

  if (authContext.tokenVerificationInProgress) {
    return (
      <div
        style={{
          textAlign: 'center',
          padding: '25% 0',
        }}
      >
        <Spinner />
      </div>
    )
  }

  return authContext.isAuthenticated ? (
    <HeaderRoute currentRoute={current} setCurrentRoute={setCurrent} />
  ) : (
    <Navigate
      to={`/login${
        intendedPath && intendedPath !== '/'
          ? `?${REDIRECT_PARAM}=${intendedPath}`
          : ''
      }`}
      replace
    />
  )
}

export default ProtectedRoute
