import { evoClient, putPart } from '$api/client'
import { UilCube, UilTruck, UilUpload } from '@iconscout/react-unicons'
import { useMutation } from '@tanstack/react-query'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  notification,
  Popover,
} from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Form from '$components/Form'
import UploadFiles from './UploadFiles'
import { ExpandableTableCard, Title } from './styledComponents'
import ShortUniqueId from 'short-unique-id'

const { TextArea } = Input

const Partdetailform = (props) => {
  const [sendback, setSendback] = useState(false)
  const [checkedList, setCheckedList] = useState(['other upload'])
  const [fileList, setFileList] = useState([])
  const CheckboxGroup = Checkbox.Group
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const uid = new ShortUniqueId({ length: 6, dictionary: 'alphanum_upper' })
  const [trackingnumber] = useState(uid.rnd())
  //const trackingnumber = uid.rnd()

  useMutation(
    () => {
      return evoClient.put(
        putPart + props.record.id,
        JSON.stringify(props.record),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
    },
    {
      onSuccess: () => {
        openNotification('top', t(''))
        // queryClient.invalidateQueries(["allgroups"]); //check
      },
    },
  )

  const onFinish = (values) => {
    // check if there are some files attached
    if (checkedList.length > 0 && checkedList.includes('other upload')) {
      validateFiles()
    }
    // set status for each part to "finished"

    if (
      checkedList.length > 0 &&
      fileList.length > 0 &&
      checkedList.includes('other upload')
    ) {
      props.setStatus([true, props.record.id])
    } else if (
      checkedList.length > 0 &&
      fileList.length === 0 &&
      checkedList.includes('other upload')
    ) {
      props.setStatus([false, props.record.id])
    } else if (
      checkedList.length > 0 &&
      !checkedList.includes('other upload')
    ) {
      props.setStatus([true, props.record.id])
    } else if (
      !checkedList.includes('delivery') &&
      !checkedList.includes('stl') &&
      !checkedList.includes('other upload')
    ) {
      openErrorNotification('top', 'Bitte wähle mindestens eine Option')
    }
    // post to endpoints
    const schema = {
      service_id: 'string',
      company: 'string',
      service_name: 'string',
      part: {
        id: props.record.id,
        name: props.record.name,
        id_part_client: props.record.id_part_client,
        db_id_client: props.record.id,
        company: props.record.company,
        attributes: props.record.attributes,
        float_attributes: props.record.float_attributes,
        status_attributes: props.record.status_attributes,
        dwh_materials: props.record.dwh_materials,
      },
      address: {
        name: values.company ? values.company : '',
        city: values.city ? values.city : '',
        country: values.country ? values.country : '',
        postcode: values.postalcode ? values.postalcode.toString() : '',
        state: values.state ? values.state : '',
        address_line_1: values.address1 ? values.address1 : '',
        address_line_2: values.address2 ? values.address2 : '',
      },
      is_digital: checkedList.includes('stl') ? true : false,
      tracking_number: trackingnumber,
      send_back: sendback,
      specifications: values.specifications,
      information_quality: checkedList,
      files: fileList,
    }
    props.setCurrentFormValues(schema)
  }

  const onCheckboxChange = (list) => {
    setCheckedList(list)
  }

  const openNotification = (placement, message) => {
    notification.success({
      message: `${message}`,
      description: ``,
      placement,
    })
  }

  const openErrorNotification = (placement, message) => {
    notification.error({
      message: `${message}`,
      description: ``,
      placement,
    })
  }

  const onChangeSwitch1 = (v) => {
    setSendback(v)
  }

  const validateFiles = () => {
    if (fileList.length === 0 && checkedList.length > 0) {
      // Handle the case where no files are selected
      openErrorNotification(
        'top',
        t('service.generate_3d_model.must_upload_spec'),
      )
      return false
    } else {
      return true
    }
  }

  const renderElementsByCheckbox = () => {
    if (!checkedList) {
      return null
    }
    return (
      <div>
        {checkedList.map((item, index) => {
          if (item === 'other upload') {
            return (
              <div key={index}>
                <UploadFiles
                  record={props.record}
                  setFileList={setFileList}
                  fileList={fileList}
                />
              </div>
            )
          } else if (item === 'delivery') {
            return (
              <div key={index}>
                <h3 style={{ marginTop: 20 }}>
                  {t('service.generate_3d_model.send_in_part.instruction')}:
                </h3>
                <Row className="row">
                  <Col flex="1 0 25%">
                    <h4>Daimler Buses GmbH</h4>
                    Otto-Renner-Straße 40
                    <br />
                    89231 Neu-Ulm
                    <br />
                    Deutschland
                    <br />
                    <br />
                    <Form.Item
                      label={t(
                        'service.generate_3d_model.send_in_part.request_return',
                      )}
                      name="sendback"
                      valuePropName="checked"
                      style={{ height: '14px' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                        }}
                      >
                        <Switch
                          style={{ marginTop: '-44px' }}
                          defaultChecked={sendback}
                          onChange={onChangeSwitch1}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col flex="1 0 25%">
                    <h4>{t('service.generate_3d_model.send_in_part.code')}</h4>
                    {trackingnumber}
                  </Col>
                </Row>
                <br />
                {sendback ? (
                  <>
                    <h4>
                      {t(
                        'service.generate_3d_model.send_in_part.return_address',
                      )}
                    </h4>

                    <Form.Item
                      name="company"
                      label={t(
                        'service.generate_3d_model.send_in_part.company_name',
                      )}
                      required
                    >
                      <Input
                        placeholder={t(
                          'service.generate_3d_model.send_in_part.input_company_name',
                        )}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t(
                        'service.generate_3d_model.send_in_part.address_line',
                      )}
                      style={{ marginBottom: 0, width: '101.1%' }}
                      required
                    >
                      <Form.Item
                        name="address1"
                        rules={[
                          {
                            required: true,
                            message: t(
                              'service.generate_3d_model.send_in_part.address_required',
                            ),
                          },
                        ]}
                        style={{
                          display: 'inline-block',
                          width: 'calc(50% - 8px)',
                        }}
                      >
                        <Input placeholder="Input Address line 1 / Street Name / PO Box" />
                      </Form.Item>
                      <Form.Item
                        name="address2"
                        style={{
                          display: 'inline-block',
                          width: 'calc(50% - 8px)',
                          margin: '0 8px',
                        }}
                      >
                        <Input placeholder="Input Address line 2 / Floor / Building / Unit" />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      label="Postal Code / City"
                      style={{ marginBottom: 0, width: '102%' }}
                      required
                    >
                      <Form.Item
                        name="postalcode"
                        rules={[{ required: true, message: t('Required') }]}
                        style={{
                          display: 'inline-block',
                          width: 'calc(16.6% - 8px)',
                        }}
                      >
                        <InputNumber
                          placeholder="Input Postal Code / ZIP / PLZ"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: t(
                              'service.generate_3d_model.send_in_part.city_required',
                            ),
                          },
                        ]}
                        style={{
                          display: 'inline-block',
                          width: 'calc(33% - 8px)',
                          margin: '0 8px',
                        }}
                      >
                        <Input placeholder="Input City" />
                      </Form.Item>
                      <Form.Item
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: 'State / Province / Region is required',
                          },
                        ]}
                        style={{
                          display: 'inline-block',
                          width: 'calc(33% - 8px)',
                          margin: '0 8px 0 0',
                        }}
                      >
                        <Input placeholder="Input State / Province / Region" />
                      </Form.Item>
                      <Form.Item
                        name="country"
                        rules={[
                          { required: true, message: 'Country is required' },
                        ]}
                        style={{
                          display: 'inline-block',
                          width: 'calc(16.6% - 8px)',
                          margin: '0px',
                        }}
                      >
                        <Select
                          placeholder="Country"
                          variant="outlined"
                          options={[
                            { value: 'DE', label: 'DE' },
                            { value: 'FR', label: 'FR' },
                            { value: 'US', label: 'US' },
                            { value: 'CN', label: 'CN', disabled: true },
                          ]}
                        />
                      </Form.Item>
                    </Form.Item>
                  </>
                ) : null}
              </div>
            )
          } else if (item === 'stl') {
            return (
              <div key={index}>
                <p>{t('service.generate_3d_model.stl_is_send_in')}</p>
              </div>
            )
          } else {
            return <span key={index}>Default JSX for unknown string</span>
          }
        })}
      </div>
    )
  }

  return (
    <ExpandableTableCard>
      <Form
        layout="vertical"
        size="medium"
        onFinish={onFinish}
        form={form}
        // className="partdetailform"
      >
        <Col span={24}>
          <Row className="row">
            <Col flex="1 0 25%" className="formcolumn">
              <Title style={{ margin: '23px 0px' }}>
                {t('service.generate_3d_model.specification')}
              </Title>
              <p style={{ marginBottom: '7px' }}>
                {t('service.generate_3d_model.upload_instruction')}
              </p>
              <Divider style={{ margin: '15px 0 0' }} />
              <CheckboxGroup
                defaultValue={['other upload']}
                onChange={onCheckboxChange}
                style={{ justifyContent: 'space-between', width: '100%' }}
              >
                <Button type="text" style={{ height: 'auto' }}>
                  <Checkbox value="other upload">
                    <div style={{ padding: '10px 0', lineHeight: 'inherit' }}>
                      <UilUpload
                        style={{
                          height: '15px',
                          margin: '0px 5px -3px 0px',
                        }}
                      />
                      <span style={{ lineHeight: 1.6 }}>
                        {t(
                          'service.generate_3d_model.specification_via_upload',
                        )}
                      </span>
                    </div>
                  </Checkbox>
                </Button>
                <Button type="text" style={{ height: 'auto' }}>
                  <Checkbox value="delivery">
                    <div style={{ padding: '10px 0', lineHeight: 'inherit' }}>
                      <UilTruck
                        style={{
                          height: '15px',
                          margin: '0px 5px -3px 0px',
                        }}
                      />
                      <span style={{ lineHeight: 1.6 }}>
                        {t(
                          'service.generate_3d_model.specification_via_send_in',
                        )}
                      </span>
                    </div>
                  </Checkbox>
                </Button>
                <Popover
                  title={
                    !props.record.has3dModel &&
                    t('service.generate_3d_model.part_has_no_3d_model')
                  }
                  trigger="hover"
                >
                  <Button type="text" style={{ height: 'auto' }}>
                    <Checkbox value="stl" disabled={!props.record.has3dModel}>
                      <div style={{ padding: '10px 0', lineHeight: 'inherit' }}>
                        <UilCube
                          style={{
                            height: '16px',
                            margin: '0px 5px -3px 0px',
                          }}
                        />
                        <span style={{ lineHeight: 1.6 }}>
                          {t(
                            'service.generate_3d_model.specification_via_existing',
                          )}
                        </span>
                      </div>
                    </Checkbox>
                  </Button>
                </Popover>
              </CheckboxGroup>
              <Divider style={{ margin: '0 0 10px ' }} />
              {renderElementsByCheckbox()}
            </Col>
            <Divider
              type="vertical"
              className="partsdivider"
              style={{ marginTop: '67px' }}
            />
            <Col flex="1 0 25%" className="formcolumn">
              <Title style={{ margin: '23px 0px' }}>
                {t('Additional information')} ({t('Optional')})
              </Title>
              <Form.Item
                label={t('service.generate_3d_model.additional_specifications')}
                name="specifications"
              >
                <TextArea
                  rows={11}
                  variant="borderless"
                  className="white-bg"
                  showCount={true}
                  maxLength={1000}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Form.Item style={{ float: 'right', marginTop: '20px' }}>
          <Space>
            <Button type="primary" htmlType="submit">
              {t('button.save')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ExpandableTableCard>
  )
}

export default Partdetailform
