import {
  UilFileUploadAlt,
  UilImages,
  UilMultiply,
  UilUpload,
} from '@iconscout/react-unicons'
import {
  Button,
  Col,
  Divider,
  Input,
  InputNumber,
  Popover,
  Row,
  Segmented,
  Space,
  Switch,
  notification,
} from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { evoClient, putPart } from '$api/client'
import ImageUpload from '$components/FileUpload/ImageUpload'
import BumpmapGallery from './BumpmapGallery'
import { ExpandableTableCard, GalleryOpen, Title } from './styledComponents'
import Form from '$components/Form'

const { TextArea } = Input

const Partdetailform = (props) => {
  const [uploadState, setUploadState] = useState('uploadbump')
  const [visibleSideSwitch, setVisibleSideSwitch] = useState(false)
  const [notVisibleSideSwitch, setNotVisibleSideSwitch] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState()
  const [imageFile, setImageFile] = useState()
  const [stlFile, setSTLFile] = useState()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  useEffect(() => {
    evoClient
      .get(putPart + props.record.id + '/stl/', {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
      })
      .then(async (resp) => {
        const asyncBlob = await resp.data.arrayBuffer()
        const blob = new Blob([asyncBlob], {
          type: 'application/vnd.ms-pki.stl',
        })
        setSTLFile(blob)
      })
  }, [props.record.id])

  const onFinish = (values) => {
    if (
      !imageFile &&
      (uploadState === 'uploadbump' || uploadState === 'uploadother')
    ) {
      openErrorNotification(
        'top',
        t('service.apply_texturing.must_upload_files'),
      )
      return
    } else if (!selectedImage && uploadState === 'choosebump') {
      openErrorNotification(
        'top',
        t('service.apply_texturing.must_select_bumpmap'),
      )
      return
    }

    // set status for each part to "finished"
    props.setStatus([true, props.record.id])
    // post to endpoints
    const schema = {
      service_id: 'string',
      company: 'string',
      service_name: 'string',
      part: {
        id: props.record.id,
        name: props.record.name,
        id_part_client: props.record.id_part_client,
        db_id_client: props.record.id,
        company: props.record.company,
        attributes: props.record.attributes,
        float_attributes: props.record.float_attributes,
        status_attributes: props.record.status_attributes,
        dwh_materials: props.record.dwh_materials,
      },
      visible_side: visibleSideSwitch,
      visible_where: values.visibleWhere,
      not_visible_side: notVisibleSideSwitch,
      specifications: values.specifications,
      depth_of_grain: values.depthOfGrain,
      user_texture_file: imageFile,
      stl_file: stlFile,
    }
    props.setCurrentFormValues(schema)
  }

  const onReset = () => {
    openNotification('top', 'Änderungen verworfen')
    form.resetFields()
  }

  const openNotification = (placement, message) => {
    notification.success({
      message: `${message}`,
      description: ``,
      placement,
    })
  }

  const openErrorNotification = (placement, message) => {
    notification.error({
      message: `${message}`,
      description: ``,
      placement,
    })
  }

  const onUploadChange = (v) => {
    setUploadState(v)
  }
  const onChangeSwitch2 = (v) => {
    setVisibleSideSwitch(v)
  }
  const onChangeSwitch3 = (v) => {
    setNotVisibleSideSwitch(v)
  }

  const returnUploadState = () => {
    switch (uploadState) {
      case 'uploadbump':
        return <ImageUpload setImageFile={setImageFile} />
      case 'choosebump':
        return (
          <GalleryOpen>
            <Button onClick={() => setIsModalOpen(true)}>
              {t('service.apply_texturing.choose_bump')}
            </Button>
            <BumpmapGallery
              record={props.record}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              setSelectedImage={setSelectedImage}
            />
          </GalleryOpen>
        )
      case 'uploadother':
        return <ImageUpload setImageFile={setImageFile} />

      default:
        return <ImageUpload setImageFile={setImageFile} />
    }
  }

  return (
    <ExpandableTableCard>
      <Form
        layout="vertical"
        size="medium"
        onFinish={onFinish}
        onReset={onReset}
        form={form}
        initialValues={{
          depthOfGrain: 100,
        }}
      >
        <Col span={24}>
          <Row className="row">
            <Col flex="1 0 25%" className="formcolumn">
              <Title style={{ margin: '23px 0px' }}>
                {t('service.apply_texturing.please_upload_or_choose')}
              </Title>
              {!selectedImage ? (
                <>
                  <Segmented
                    block
                    onChange={onUploadChange}
                    options={[
                      {
                        label: (
                          <div
                            style={{ padding: '10px 0', lineHeight: 'inherit' }}
                          >
                            <UilUpload style={{ width: '20px' }} />
                            <div style={{ lineHeight: 1.6 }}>
                              <Trans
                                i18nKey="service.apply_texturing.upload_own_bumpmap"
                                components={{ 1: <br /> }}
                              />
                            </div>
                          </div>
                        ),
                        value: 'uploadbump',
                      },
                      {
                        label: (
                          <Popover content={t('currently_not_supported')}>
                            <div
                              style={{
                                padding: '10px 0',
                                lineHeight: 'inherit',
                              }}
                            >
                              <UilImages style={{ width: '20px' }} />
                              <div style={{ lineHeight: 1.6 }}>
                                <Trans
                                  i18nKey="service.apply_texturing.choose_our_bumpmaps"
                                  components={{ 1: <br /> }}
                                />
                              </div>
                            </div>
                          </Popover>
                        ),
                        value: 'choosebump',
                        disabled: true,
                      },
                      {
                        label: (
                          <Popover content={t('currently_not_supported')}>
                            <div
                              style={{
                                padding: '10px 0',
                                lineHeight: 'inherit',
                              }}
                            >
                              <UilFileUploadAlt style={{ width: '20px' }} />
                              <div style={{ lineHeight: 1.6 }}>
                                <Trans
                                  i18nKey="service.apply_texturing.upload_other_files"
                                  components={{ 1: <br /> }}
                                />
                              </div>
                            </div>
                          </Popover>
                        ),
                        value: 'uploadother',
                        disabled: true,
                      },
                    ]}
                  />
                  {returnUploadState()}
                </>
              ) : (
                <div>
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: 99,
                      margin: '10px 0 0 250px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setSelectedImage(undefined)}
                  >
                    <UilMultiply style={{ color: 'white' }} />
                  </div>
                  {selectedImage}
                </div>
              )}
            </Col>
            <Divider
              type="vertical"
              className="partsdivider"
              style={{ marginTop: '67px' }}
            />
            <Col flex="1 0 25%" className="formcolumn">
              <Title style={{ margin: '23px 0px' }}>
                {t('Additional information')} ({t('Optional')})
              </Title>
              <Form.Item
                label={t('service.apply_texturing.depth_of_surface_texture')}
                name="depthOfGrain"
                rules={[
                  {
                    required: true,
                    message: t(
                      'service.apply_texturing.missing_depth_of_grain',
                    ),
                  },
                ]}
              >
                <InputNumber
                  className="white-bg"
                  style={{ width: '100%' }}
                  variant="borderless"
                  min={0}
                  max={1000}
                  formatter={(value) => `${value} µm`}
                  parser={(value) => value.replace(' µm', '')}
                  // onChange={onChange}
                />
                {/* <TextArea rows={4} variant='borderless' className="white-bg" defaultValue="" /> */}
              </Form.Item>
              <Form.Item
                label={t('service.apply_texturing.additional_specifications')}
                name="specifications"
              >
                <TextArea rows={4} variant="borderless" className="white-bg" />
              </Form.Item>
            </Col>
            <Divider
              type="vertical"
              className="partsdivider"
              style={{ marginTop: '67px' }}
            />
            <Col flex="1 0 25%" className="formcolumn formcolumnlast">
              <Title style={{ margin: '23px 0px' }}>
                {t('service.apply_texturing.visible_side')}
              </Title>
              <Form.Item
                label={t('Is there a visible side')}
                name="visibleside"
                valuePropName="checked"
              >
                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <Switch
                    defaultChecked={visibleSideSwitch}
                    onChange={onChangeSwitch2}
                  />
                </div>
              </Form.Item>
              {visibleSideSwitch ? (
                <>
                  <Form.Item
                    label={t('Where?')}
                    name="visibleWhere"
                    style={{ marginTop: -30 }}
                  >
                    <TextArea
                      rows={4}
                      variant="borderless"
                      className="white-bg"
                    />
                  </Form.Item>
                  <Form.Item
                    className="longlabelcheckbox"
                    label={
                      <div
                        style={{
                          overflowWrap: 'break-word',
                          wordBreak: 'break-word',
                          display: 'inline-block',
                          whiteSpace: 'break-spaces',
                        }}
                      >
                        {t('service.apply_texturing.only_on_visible_side')}
                      </div>
                    }
                    name="notvisibleside"
                    valuePropName="checked"
                  >
                    <div
                      style={{ display: 'flex', flexDirection: 'row-reverse' }}
                    >
                      <Switch
                        defaultChecked={notVisibleSideSwitch}
                        onChange={onChangeSwitch3}
                      />
                    </div>
                  </Form.Item>
                </>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Form.Item style={{ float: 'right', marginTop: '20px' }}>
          <Space>
            <Button type="primary" htmlType="submit">
              {t('button.save')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ExpandableTableCard>
  )
}

export default Partdetailform
