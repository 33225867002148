import { refreshAccessToken, logout } from '$api/authentication'
import { SESSION_EXPIRED } from '$constants'

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const tokenRefreshInterceptor = (axiosInstance) => (error) => {
  const _axios = axiosInstance
  const originalRequest = error.config

  if ([401, 403].includes(error.response.status) && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      })
        .then((token) => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token
          return _axios.request(originalRequest)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    }

    originalRequest._retry = true
    isRefreshing = true

    return new Promise((resolve, reject) => {
      refreshAccessToken()
        .then(({ access_token }) => {
          processQueue(null, access_token)
          originalRequest.headers['Authorization'] = 'Bearer ' + access_token
          resolve(_axios(originalRequest))
        })
        .catch(async (err) => {
          processQueue(err, null)
          reject(err)
          await logout()
          window.location.href = `/login?${SESSION_EXPIRED}`
        })
        .then(() => {
          isRefreshing = false
        })
    })
  }

  return Promise.reject(error)
}

export default tokenRefreshInterceptor
