import { useRef, useContext, useState } from 'react'
import { useSearchParams, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Row, Segmented, Divider, notification, Flex } from 'antd'
import { StyledLayout } from '$globalStyledComponents/Layout'
import LoginBackground from '$assets/img/digital-storage.png'
import DaimlerBusesLogo from '$assets/logos/Daimler_Buses_Logotype_1L_Black_RGB.svg'
import {
  RightSideImageContainer,
  LeftSideFormContainer,
  LoginContainer,
  SusiCloundBackgroundImg,
  FormContainer,
  HeaderLogo,
  LogoWrapper,
  OrangeText,
  GreyText,
  WideButton,
  LoginAlert,
  LinksContainerBottom,
} from './StyledComponents'
import { AuthContext } from 'react-oauth2-code-pkce'
import { Link } from 'react-router-dom'
import { ErrorWithCode } from '$constants/errors'
import { SESSION_EXPIRED, AUTOMATIC_LOGOUT, REDIRECT_PARAM } from '$constants'
import Form from '$components/Form'

const Login = ({ error }) => {
  const [formInstance] = Form.useForm()
  const submitButtonRef = useRef(null)
  const { t, i18n } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { token, logIn, logOut, loginInProgress } = useContext(AuthContext)
  const redirect = searchParams.get(REDIRECT_PARAM)
  const [loginLoading, setLoginLoading] = useState(false)

  const openErrorNotification = (title, text) => {
    notification.error({
      message: title,
      description: text,
      onClick: () => {},
    })
  }

  const onFinish = () => {
    try {
      setLoginLoading(true)
      logIn()
    } catch (err) {
      if (err instanceof ErrorWithCode) {
        openErrorNotification(
          t('login_failed'),
          t('login_error_with_code', { error_code: err.code }),
        )
      } else {
        openErrorNotification(t('login_failed'), t('login_error'))
      }
    } finally {
      setLoginLoading(false)
    }
  }

  const onFinishFailed = (error) => {
    console.error(error)
  }

  const openPrivacySettings = () => {
    if (window.UC_UI && window.UC_UI.showSecondLayer) {
      window.UC_UI.showSecondLayer()
    } else {
      console.error('UserCentrics UI is not available.')
    }
  }

  const languages = [
    {
      label: 'DE',
      value: 'de',
    },
    {
      label: 'EN',
      value: 'en',
    },
  ]

  if (token && !error) {
    return <Navigate to={redirect || '/'} />
  } else if (!loginInProgress) {
    return (
      <StyledLayout className="layout">
        <Row>
          <RightSideImageContainer>
            <SusiCloundBackgroundImg src={LoginBackground} />
          </RightSideImageContainer>
          <LeftSideFormContainer>
            <LogoWrapper>
              <HeaderLogo
                src={DaimlerBusesLogo}
                alt="Daimler Truck - Daimler Buses"
              />
            </LogoWrapper>
            <LoginContainer>
              {!token && (
                <Flex vertical="true" align="center" style={{ width: '100%' }}>
                  <OrangeText>{t('welcome_back')}</OrangeText>
                  <GreyText>{t('login_and_go')}</GreyText>
                </Flex>
              )}

              <FormContainer>
                <Form
                  onFinish={token ? logOut : onFinish}
                  onFinishFailed={onFinishFailed}
                  form={formInstance}
                  layout="vertical"
                >
                  {error && (
                    <LoginAlert
                      message={
                        error.code
                          ? t('authentication_error_with_code', {
                              error_code: error.code,
                            })
                          : error.message
                      }
                      type="warning"
                      closable={false}
                    />
                  )}
                  {searchParams.has(AUTOMATIC_LOGOUT) && (
                    <LoginAlert
                      message={t(AUTOMATIC_LOGOUT)}
                      type="warning"
                      closable={true}
                      onClose={() => setSearchParams('')}
                    />
                  )}
                  {searchParams.has(SESSION_EXPIRED) && (
                    <LoginAlert
                      message={t(SESSION_EXPIRED)}
                      type="warning"
                      closable={true}
                      onClose={() => setSearchParams('')}
                    />
                  )}
                  <WideButton
                    htmlType="submit"
                    type="primary"
                    ref={submitButtonRef}
                    loading={loginLoading}
                  >
                    {token ? 'Log out' : 'Log in'}
                  </WideButton>
                </Form>
                <Divider />
                <Flex
                  vertical="true"
                  align="flex-end"
                  style={{ width: '100%' }}
                >
                  <span>
                    {t('language')}:&nbsp;
                    <Segmented
                      options={languages}
                      value={i18n.resolvedLanguage || 'en'}
                      onChange={(lang) => i18n.changeLanguage(lang)}
                    />
                  </span>
                </Flex>
              </FormContainer>
              <LinksContainerBottom>
                {' '}
                <Divider type="vertical" />
                <Link to="/imprint">{t('Provider')}</Link>
                <Divider type="vertical" />
                <Link to="/data-privacy">{t('Data protection')}</Link>
                <br />
                <Link to="/legal-information">{t('Legal notices')}</Link>
                <Divider type="vertical" />
                <Link onClick={openPrivacySettings}>
                  {t('Privacy settings')}
                </Link>
              </LinksContainerBottom>
            </LoginContainer>
          </LeftSideFormContainer>
        </Row>
      </StyledLayout>
    )
  }
}

export default Login
