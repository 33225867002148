import PartDetailForm from '$components/PartDetailForm'

const ExpandedRow = (props) => (
  <PartDetailForm
    setBookableParts={props.setBookableParts}
    record={props.record}
    setConfigState={props.setConfigState}
    configState={props.configState}
    setStatus={props.setStatus}
    status={props.status}
    setCurrentFormValues={props.setCurrentFormValues}
  />
)

export default ExpandedRow
