import ServiceStatusButtons from '../../../PartsTable/ServiceStatusButtons'
import { PartNoBg } from '../styledComponents'
import NotBookableReason from './NotBookableReason'
import TruncateText from '$components/TruncateText/TruncateText'

const createColumnsNotFeasible = (t) => {
  const performeableservices = {
    variant: { a: 1, b: 2, c: 3, d: 4 },
  }

  const columns = [
    // {
    //   title: "Feasible",
    //   dataIndex: "feasible",
    //   className: "feas-col"
    // },
    //Table.EXPAND_COLUMN,
    {
      title: t('Part No'),
      dataIndex: 'id_part_client', //data["id_part_client"],
      //...getColumnSearchProps('id_part_client'),
      render: (title) => <PartNoBg>{title}</PartNoBg>,
      width: '160px',
    },
    {
      title: t('Part Name'),
      dataIndex: 'name',
      render: (text) => <TruncateText text={text} />,
      ellipsis: true,
      //...getColumnSearchProps('name'),
    },
    {
      title: t('Reason'),
      dataIndex: 'reason',
      render: (data) => {
        return (
          <NotBookableReason
            title={data.title}
            description={data.description}
          />
        )
      },
    },
    {
      title: t('Services'),
      dataIndex: 'attributes',
      width: '265px',
      render: () => (
        <ServiceStatusButtons variant={performeableservices.variant} />
      ),
    },
  ]

  return columns
}

export default createColumnsNotFeasible
