import { TourContext } from '$components/GuidedTour'
import VERSIONS from '$constants/versions.json'
import { Divider } from 'antd'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { StyledLayout } from '../globalStyledComponents/Layout'
import { AuthContext } from '$context/AuthContextProvider'

const { Footer: StyledFooter } = StyledLayout

const Footer = () => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const openPrivacySettings = () => {
    if (window.UC_UI && window.UC_UI.showSecondLayer) {
      window.UC_UI.showSecondLayer()
    } else {
      console.error('UserCentrics UI is not available.')
    }
  }
  const { onSetTour } = useContext(TourContext)

  const LATEST_COMMIT_CLIENT =
    window.REACT_APP_LATEST_COMMIT_CLIENT ||
    process.env.REACT_APP_LATEST_COMMIT_CLIENT ||
    VERSIONS.LATEST_COMMIT_CLIENT
  const LATEST_COMMIT_CONSOLIDATION =
    window.REACT_APP_LATEST_COMMIT_CONSOLIDATION ||
    process.env.REACT_APP_LATEST_COMMIT_CONSOLIDATION ||
    VERSIONS.LATEST_COMMIT_CONSOLIDATION
  const LATEST_COMMIT_FRONTEND =
    window.REACT_APP_LATEST_COMMIT_FRONTEND ||
    process.env.REACT_APP_LATEST_COMMIT_FRONTEND ||
    VERSIONS.LATEST_COMMIT_FRONTEND
  const DEPLOYMENT_TIMESTAMP =
    window.REACT_APP_EPLOYMENT_TIMESTAMP ||
    process.env.REACT_APP_DEPLOYMENT_TIMESTAMP ||
    VERSIONS.DEPLOYMENT_TIMESTAMP

  return (
    <StyledFooter style={{ textAlign: 'left' }}>
      <Link
        to="https://locations.daimlertruck.com/karriere/standorte/detail/neu-ulm-daimler-buses-gmbh"
        target="_blank"
      >
        {t('localized_datetime', {
          val: new Date(),
          formatParams: {
            val: {
              year: 'numeric',
            },
          },
        })}{' '}
        © Daimler Buses GmbH. {t('All rights reserved')}.
      </Link>
      <Divider type="vertical" />
      <Link to="/imprint">{t('Provider')}</Link>
      <Divider type="vertical" />
      <Link to="/data-privacy">{t('Data protection')}</Link>
      <Divider type="vertical" />
      <Link to="/legal-information">{t('Legal notices')}</Link>
      <Divider type="vertical" />
      <Link onClick={openPrivacySettings}>{t('Privacy settings')}</Link>
      <Divider type="vertical" />
      {authContext.isServiceUser() && (
        <>
          <Link onClick={onSetTour}>{t('open_tour', { ns: 'tour' })}</Link>
          <Divider type="vertical" />
        </>
      )}
      <p style={{ color: '#ccc' }}>
        cl: {LATEST_COMMIT_CLIENT} co: {LATEST_COMMIT_CONSOLIDATION} ui:{' '}
        {LATEST_COMMIT_FRONTEND} at: {DEPLOYMENT_TIMESTAMP}
      </p>
    </StyledFooter>
  )
}

export default Footer
