import { Outlet } from 'react-router-dom'
import Topnav from '$components/Topnav'
import { StyledHeader } from '$globalStyledComponents/Navbar'

const HeaderRoute = ({ currentRoute, setCurrentRoute }) => {
  return (
    <>
      <StyledHeader>
        <Topnav current={currentRoute} setCurrent={setCurrentRoute} />
      </StyledHeader>
      <div id="Topnavbg" />

      <Outlet />
    </>
  )
}

export default HeaderRoute
