import { useRef, useContext, useState } from 'react'
import { useSearchParams, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  Input,
  Segmented,
  Button,
  Divider,
  notification,
  Popconfirm,
  Modal,
  Flex,
} from 'antd'
import { StyledLayout } from '$globalStyledComponents/Layout'
import LoginBackground from '$assets/img/digital-storage.png'
import DaimlerBusesLogo from '$assets/logos/Daimler_Buses_Logotype_1L_Black_RGB.svg'
import {
  RightSideImageContainer,
  LeftSideFormContainer,
  LoginContainer,
  SusiCloundBackgroundImg,
  FormContainer,
  HeaderLogo,
  LogoWrapper,
  OrangeText,
  GreyText,
  WideButton,
  LoginAlert,
  LinksContainerBottom,
} from './StyledComponents'
import { AuthContext } from '$context/AuthContextProvider'
import { Link } from 'react-router-dom'
import {
  IncorrectCredentialsError,
  UpdatePasswordRequiredError,
} from '$constants/errors/authentication'
import { ErrorWithCode } from '$constants/errors'
import { SESSION_EXPIRED, AUTOMATIC_LOGOUT, REDIRECT_PARAM } from '$constants'
import Form from '$components/Form'

const Login = () => {
  const [formInstance] = Form.useForm()
  const submitButtonRef = useRef(null)
  const { t, i18n } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const authContext = useContext(AuthContext)
  const [showRedirectModal, setShowRedirectModal] = useState(false)
  const redirect = searchParams.get(REDIRECT_PARAM)
  const [loginLoading, setLoginLoading] = useState(false)

  const openErrorNotification = (title, text) => {
    notification.error({
      message: title,
      description: text,
      onClick: () => {},
    })
  }

  const onFinish = async (values) => {
    try {
      setLoginLoading(true)
      await authContext.login(values)
    } catch (err) {
      if (err instanceof UpdatePasswordRequiredError) {
        setShowRedirectModal(true)
      } else if (err instanceof IncorrectCredentialsError) {
        openErrorNotification(t('login_failed'), t('invalid_grant'))
      } else if (err instanceof ErrorWithCode) {
        openErrorNotification(
          t('login_failed'),
          t('login_error_with_code', { error_code: err.code }),
        )
      } else {
        openErrorNotification(t('login_failed'), t('login_error'))
      }
    } finally {
      setLoginLoading(false)
    }
  }

  const onFinishFailed = (error) => {
    console.error(error)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      submitButtonRef.current.click()
    }
  }

  const openPrivacySettings = () => {
    if (window.UC_UI && window.UC_UI.showSecondLayer) {
      window.UC_UI.showSecondLayer()
    } else {
      console.error('UserCentrics UI is not available.')
    }
  }

  const languages = [
    {
      label: 'DE',
      value: 'de',
    },
    {
      label: 'EN',
      value: 'en',
    },
  ]

  if (authContext.isAuthenticated) {
    return <Navigate to={redirect || '/'} />
  } else if (!authContext.tokenVerificationInProgress) {
    return (
      <StyledLayout className="layout">
        <Row>
          <RightSideImageContainer>
            <SusiCloundBackgroundImg src={LoginBackground} />
          </RightSideImageContainer>
          <LeftSideFormContainer>
            <LogoWrapper>
              <HeaderLogo
                src={DaimlerBusesLogo}
                alt="Daimler Truck - Daimler Buses"
              />
            </LogoWrapper>
            <LoginContainer>
              <Flex vertical="true" align="center" style={{ width: '100%' }}>
                <OrangeText>{t('welcome_back')}</OrangeText>
                <GreyText>{t('login_and_go')}</GreyText>
              </Flex>

              <FormContainer>
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  form={formInstance}
                  layout="vertical"
                >
                  {searchParams.has(AUTOMATIC_LOGOUT) && (
                    <LoginAlert
                      message={t(AUTOMATIC_LOGOUT)}
                      type="warning"
                      closable={true}
                      onClose={() => setSearchParams('')}
                    />
                  )}
                  {searchParams.has(SESSION_EXPIRED) && (
                    <LoginAlert
                      message={t(SESSION_EXPIRED)}
                      type="warning"
                      closable={true}
                      onClose={() => setSearchParams('')}
                    />
                  )}
                  <Form.Item
                    name="username"
                    label={t('Username')}
                    rules={[
                      { required: true, message: t('username_required') },
                    ]}
                  >
                    <Input
                      placeholder={t('Enter Username')}
                      onKeyDown={handleKeyPress}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label={t('Password')}
                    type="password"
                    rules={[
                      { required: true, message: t('password_required') },
                    ]}
                  >
                    <Input.Password
                      placeholder={t('Enter Password')}
                      onKeyDown={handleKeyPress}
                    />
                  </Form.Item>

                  <WideButton
                    htmlType="submit"
                    type="primary"
                    ref={submitButtonRef}
                    loading={loginLoading}
                  >
                    Log in
                  </WideButton>
                </Form>
                <Divider />
                <Row justify="space-between" align="middle">
                  <Col>
                    <Popconfirm
                      title={t('forgot_pw_redirection_notice')}
                      okText={t('button.ok')}
                      showCancel={false}
                      onConfirm={() => authContext.initiateResetPasswordFlow()}
                      overlayStyle={{
                        width: '350px',
                      }}
                    >
                      <Button type="text">{t('forgot_password?')}</Button>
                    </Popconfirm>
                  </Col>
                  <Col>
                    {t('language')}:&nbsp;
                    <Segmented
                      options={languages}
                      value={i18n.resolvedLanguage || 'en'}
                      onChange={(lang) => i18n.changeLanguage(lang)}
                    />
                  </Col>
                </Row>
              </FormContainer>
              <LinksContainerBottom>
                {' '}
                <Divider type="vertical" />
                <Link to="/imprint">{t('Provider')}</Link>
                <Divider type="vertical" />
                <Link to="/data-privacy">{t('Data protection')}</Link>
                <br />
                <Link to="/legal-information">{t('Legal notices')}</Link>
                <Divider type="vertical" />
                <Link onClick={openPrivacySettings}>
                  {t('Privacy settings')}
                </Link>
              </LinksContainerBottom>
            </LoginContainer>
          </LeftSideFormContainer>
        </Row>

        <Modal
          title={t('update_password_required.title')}
          cancelButtonProps={{ style: { display: 'none' } }}
          closable={false}
          open={showRedirectModal}
          onOk={() => authContext.initiateUpdatePasswordFlow()}
          onCancel={() => setShowRedirectModal(false)}
        >
          <p>{t('update_password_required.description')}</p>
        </Modal>
      </StyledLayout>
    )
  }
}

export default Login
