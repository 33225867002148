import { useState, useContext } from 'react'
import { Col, Divider, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import DownloadFiles from '$components/Services/OrderedServicesDetails/DownloadFiles'
import OrderPartsTableView from '$components/Services/OrderPartsTable/OrderPartsTableView'
import { fetchMaterialById } from '$api/evoAPIs'
import { AuthContext } from '$context/AuthContextProvider'
import { LabelText } from '$components/Services/styledComponents'

const tData = (data) =>
  Array.isArray(data) && data?.map((el, i) => ({ key: i, ...el }))

const OrderPartDetails = ({ record }) => {
  const [recordModified, setRecordModified] = useState([])
  const authContext = useContext(AuthContext)
  const { t } = useTranslation()
  const RecordWithMaterialName = async (record) => {
    try {
      const res = await fetchMaterialById(record.material.id)
      record.material.materialname = res.name
      setRecordModified(record)
    } catch (error) {
      console.error('Error fetching material:', error)
    }
  }
  RecordWithMaterialName(record)

  return (
    <>
      <Row>
        <Col span={12} style={{ paddingRight: 30 }}>
          {record?.user_file_location && (
            <DownloadFiles record={record} style={{ marginBottom: '18px' }} />
          )}
        </Col>
        <Col span={12}>
          {authContext?.user.role === 'serviceprovider_superadmin' ? (
            <>
              <LabelText>{t('info')}</LabelText>
              <p>{t('bookedservices.execute_service')}</p>
            </>
          ) : null}
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col span={24} style={{ paddingRight: 30 }}>
          <LabelText>{t('service.order_part.ordered_part')}</LabelText>
          <OrderPartsTableView dataSource={tData([recordModified])} />
        </Col>
      </Row>
      <Divider />
      <Row style={{ width: '100%' }}>
        <Col span={12} style={{ paddingRight: 30 }}>
          <LabelText>
            {t('service.order_part.order_adress.delivery_address')}
          </LabelText>
          <p>
            {record.delivery_address.name}
            <br />
            {record.delivery_address.address_line_1},{' '}
            {record.delivery_address.address_line_2}
          </p>
          <p>
            {record.delivery_address.postcode} {record.delivery_address.city},{' '}
            {record.delivery_address.state}
            <br />
            {record.delivery_address.country}
          </p>
        </Col>
        <Col span={12} style={{ paddingRight: 30 }}>
          <LabelText>
            {t('service.order_part.order_adress.billing_address')}
          </LabelText>
          <p>
            {record.billing_address.name}
            <br />
            {record.billing_address.address_line_1},{' '}
            {record.billing_address.address_line_2}
          </p>
          <p>
            {record.billing_address.postcode} {record.billing_address.city},{' '}
            {record.billing_address.state}
            <br />
            {record.billing_address.country}
          </p>
        </Col>
      </Row>
    </>
  )
}

export default OrderPartDetails
