import { notification } from 'antd'
import { useTrail, animated as a } from 'react-spring'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SERVICES } from '$constants'
import { ServiceButtonSpace } from './styledComponents'
import ServiceButton from './ServiceButton'

const config = { mass: 6, tension: 2800, friction: 150 }

const ServiceButtons = ({ setBookedServiceStatus, selectedParts }) => {
  const [api, contextHolder] = notification.useNotification()
  let navigate = useNavigate()
  const { t } = useTranslation()

  const trail = useTrail(SERVICES.length, {
    config,
    opacity: 1,
    enter: (function () {
      if (
        typeof document.getElementById('contextmenu') != 'undefined' &&
        document.getElementById('contextmenu') != null &&
        Object.keys(selectedParts).length > 0
      ) {
        document.getElementById('contextmenu').style.visibility = 'initial'
      }
    })(),
    x: 0,
    height: Object.keys(selectedParts).length > 0 ? 80 : 0,
    from: { opacity: 0.7, x: 30, height: 0 },
    onRest: (opacity) => {
      if (opacity.value.opacity === 0) {
        document.getElementById('contextmenu').style.visibility = 'hidden'
      }
    },
  })

  const openNotification = () => {
    api.info({
      message: t('Note'),
      description: t('choose_parts'),
      placement: 'top',
    })
  }

  const openOrderService = (value) => {
    //pass current SERVICE to further processing
    setBookedServiceStatus([true, value])
    navigate('/marketplace/orderservice')
  }

  const isRowSelected = Object.keys(selectedParts).length > 0

  const buttonToggle = (servicesIndex) => {
    isRowSelected ? openOrderService(servicesIndex) : openNotification()
  }

  return (
    <>
      {contextHolder}
      <ServiceButtonSpace
        id="contextmenu"
        style={{
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          gap: 16,
        }}
      >
        {trail.map(({ x, height: _height, ...rest }, index) => (
          <a.div
            key={SERVICES[index].key}
            style={{
              ...rest,
              transform: x.to((x) => `translate3d(${x}px,0,0)`),
              zIndex: '999',
            }}
          >
            {
              {
                1: (
                  <ServiceButton
                    serviceIndex={SERVICES[index]}
                    demoflag={false}
                    buttonToggle={buttonToggle}
                    isRowSelected={isRowSelected}
                    openNotification={openNotification}
                  />
                ),
                2: (
                  <ServiceButton
                    serviceIndex={SERVICES[index]}
                    demoflag={false}
                    buttonToggle={buttonToggle}
                    isRowSelected={isRowSelected}
                    openNotification={openNotification}
                  />
                ),
                3: (
                  <ServiceButton
                    serviceIndex={SERVICES[index]}
                    demoflag={false}
                    buttonToggle={buttonToggle}
                    isRowSelected={isRowSelected}
                    openNotification={openNotification}
                  />
                ),
                4: (
                  <ServiceButton
                    serviceIndex={SERVICES[index]}
                    demoflag={true}
                    disabled={true}
                    buttonToggle={buttonToggle}
                    isRowSelected={isRowSelected}
                    openNotification={openNotification}
                  />
                ),
              }[SERVICES[index].key]
            }
          </a.div>
        ))}
      </ServiceButtonSpace>
    </>
  )
}

export default ServiceButtons
