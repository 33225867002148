import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Modal, Select, Card, notification } from 'antd'
import { fetchUsers } from '$api/evoAPIs'
import styled from 'styled-components'
import Spinner from '$components/Spinner'
import { ErrorWithCode } from '$constants/errors'

const { useNotification } = notification

const CenterWrapper = styled.div`
  text-align: center;
  padding: 25% 0;
`

const AutoColumnsWrapper = styled.div`
  margin-top: 20px;

  display: flex;
  flex-flow: column wrap;
  max-height: 50vh;
  align-content: center;
  gap: 10px;
`

const ImpersonateUserSelector = ({ impersonateUser, onCancel, loggedInAs }) => {
  const { t } = useTranslation()
  const [notification, contextHolder] = useNotification()

  const {
    data: users,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['users'],
    queryFn: fetchUsers,
  })

  const [showModal, setShowModal] = useState(true)
  const [selectedUserId, setSelectedUserId] = useState()
  const [impersonationLoading, setImpersonationLoading] = useState(false)

  const loggedInAsUserId =
    isSuccess &&
    users.find(
      (user) => user.username === loggedInAs || user.email === loggedInAs,
    ).id

  const openErrorNotification = (title, text) => {
    notification.error({
      message: title,
      description: text,
      placement: 'top',
    })
  }

  const handleOk = async () => {
    try {
      setImpersonationLoading(true)
      await impersonateUser(selectedUserId || loggedInAsUserId)
      setShowModal(false)
    } catch (err) {
      if (err instanceof ErrorWithCode) {
        openErrorNotification(
          t('login_failed'),
          t('login_error_with_code', { error_code: err.code }),
        )
      } else {
        openErrorNotification(t('login_failed'), t('login_error'))
      }
    } finally {
      setImpersonationLoading(false)
    }
  }

  return (
    <>
      {contextHolder}
      <Modal
        title={t('impersonation.title')}
        open={showModal}
        onOk={handleOk}
        onCancel={onCancel}
        okButtonProps={{
          loading: impersonationLoading,
        }}
      >
        {isLoading ? (
          <CenterWrapper>
            <Spinner />
          </CenterWrapper>
        ) : (
          <>
            <Card>{t('impersonation.explanation')}</Card>
            <AutoColumnsWrapper>
              <Select
                style={{ width: 240 }}
                onChange={(userId) => setSelectedUserId(userId)}
                options={users.map((user) => ({
                  value: user.id,
                  label: user.username,
                }))}
                defaultValue={loggedInAsUserId}
              />
            </AutoColumnsWrapper>
          </>
        )}
      </Modal>
    </>
  )
}

export default ImpersonateUserSelector
