import { UilDownloadAlt } from '@iconscout/react-unicons'
import { Button, List } from 'antd'
import { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { evoConsolidation } from '$api'
import { consolidationPart } from '$api/evoAPIs'
import { LabelText } from '$pages/serviceprovider/Orderedservices/Orderedservicestable/ExpandedRow/styledComponents'
import { AuthContext } from '$context/AuthContextProvider'

const DownloadFiles = ({ record }) => {
  const authContext = useContext(AuthContext)
  const { t } = useTranslation()
  const [data, setData] = useState([])

  const getDownloadUrl = (stlname) => {
    evoConsolidation
      .get(
        authContext.isServiceProvider()
          ? `${consolidationPart}files/?file_name=${stlname}`
          : `${consolidationPart}${record.part.db_id_client}/file?file_name=${record.provider_file_location}`,
        {
          responseType: 'blob',
        },
      )
      .then(async (resp) => {
        const asyncBlob = await resp.data.arrayBuffer()
        const blob = new Blob([asyncBlob], { type: 'application/octet-stream' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = stlname
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
      .catch((error) => {
        // handle error
        console.error(error)
      })
    return stlname
  }

  useEffect(() => {
    if (record.user_file_locations) {
      setData(
        record.user_file_locations.map((entry) => ({
          title: entry,
          url: entry,
        })),
      )
    } else if (record.user_file_location) {
      setData([
        {
          title: record.user_file_location,
          url: record.user_file_location,
        },
      ])
    }
  }, [record.user_file_locations, record.user_file_location])

  return (
    <>
      <div style={{ width: 185 }}>
        <LabelText>{t('bookedservices.files')}</LabelText>
        <List
          style={{ width: 254, marginBottom: 18 }}
          bordered
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Button
                type="dashed"
                style={{ width: 200 }}
                onClick={() => getDownloadUrl(item.url)}
              >
                <span
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <UilDownloadAlt
                    size="19"
                    style={{ margin: '0px 7px -4px 0' }}
                  />
                  {item.title}
                </span>
              </Button>
            </List.Item>
          )}
        />
      </div>
    </>
  )
}

export default DownloadFiles
