import { Row, Col } from 'antd'
import Partdetailform from './Partdetailform'
import Part3dFileUpload from '$components/FileUpload/Part3dFileUpload'

const ExpandedRow = ({ record, update }) => (
  <Row gutter={[20, 0]}>
    <Col span={6}>
      <Part3dFileUpload record={record} />
    </Col>
    <Col span={18}>
      <Partdetailform record={record} update={update} />
    </Col>
  </Row>
)

export default ExpandedRow
