import { BellOutlined } from '@ant-design/icons'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Badge, Button, Popover, notification } from 'antd'
import { StyledTimeline } from '$globalStyledComponents/Navbar'
import * as dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchUnreadNotifications,
  markNotificationAsRead,
} from '../api/evoAPIs'

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const NotificationBell = () => {
  const [notifications, setNotifications] = useState([])
  const [bellCount, setBellCount] = useState(0)
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  useQuery({
    queryKey: ['notifications'],
    queryFn: fetchUnreadNotifications,
    onSuccess: (data) => {
      setBellCount(data.length)
      setNotifications(data)
    },
    onError: (err) => {
      console.error(err.response?.status)
      if (err?.response?.status === 403) {
        window.location.reload()
      } else {
        openErrorNotification(
          err.response?.status
            ? toString(err.response?.status)
            : toString(err?.message),
        )
      }
    },
  })
  const openErrorNotification = (err) => {
    notification.error({
      message: 'Network Error',
      description:
        err + `: There is a server error, cannot fetch notifications`,
      onClick: () => {},
    })
  }
  const content = (
    <>
      <StyledTimeline
        mode="left"
        items={notifications.map((noti) => ({
          label: dayjs(noti.created_at).fromNow(),
          children: (
            <>
              <div dangerouslySetInnerHTML={{ __html: noti.detail_text }} />
              <Button
                onClick={async () => {
                  await markNotificationAsRead(noti.id)
                  queryClient.invalidateQueries(['notifications'])
                }}
              >
                Mark as read
              </Button>
            </>
          ),
        }))}
      />
      <div style={{ textAlign: 'center' }}>{/* <a>View More...</a> */}</div>
    </>
  )

  return (
    <div className="info-circle" style={{ margin: '7px 20px 0 0' }}>
      <Badge count={bellCount}>
        {/* <StyledDropdownMenue overlayStyle={{ width: '200px' }} menu={{ items: notificationItems }} placement="bottom" trigger={['click']}> */}
        <Popover
          overlayStyle={{ width: '350px' }}
          title={<b>{t('notifications')}</b>}
          trigger="click"
          arrow={false}
          content={bellCount > 0 ? content : t('no_unread_notifications')}
        >
          <BellOutlined style={{ fontSize: '18px', paddingBottom: 20 }} />
        </Popover>
        {/* </StyledDropdownMenue> */}
      </Badge>
    </div>
  )
}

export default NotificationBell
