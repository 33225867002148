import { useContext } from 'react'
import { AuthContext, AuthProvider } from 'react-oauth2-code-pkce'
import { keycloakUrl, realm, keycloakClientId } from '$api'

const keycloakOrigin = new URL(keycloakUrl).origin

const authConfig = {
  clientId: keycloakClientId,
  authorizationEndpoint: `${keycloakOrigin}/realms/${realm}/protocol/openid-connect/auth`,
  // Note: We don't use the logoutEndpoint as we only want to logout from the
  //   Digital Warehouse but not the identity provider.
  // logoutEndpoint: `${keycloakOrigin}/realms/${realm}/protocol/openid-connect/logout`,
  tokenEndpoint: `${keycloakOrigin}/realms/${realm}/protocol/openid-connect/token`,
  redirectUri: window.location.origin,
  scope: 'profile openid',
  decodeToken: true,
  autoLogin: false,
}

const AuthContextProvider = ({ children }) => {
  const { logOut, error } = useContext(AuthContext)

  if (error) {
    return (
      <>
        <div style={{ color: 'red' }}>
          An error occurred during authentication: {error}
        </div>
        <button onClick={() => logOut(null, 'my hint')}>Log out</button>
      </>
    )
  }

  return <AuthProvider authConfig={authConfig}>{children}</AuthProvider>
}

export default AuthContextProvider
