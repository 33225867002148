import { ErrorWithCode } from '.'

// Errors wit error code
// start with 870 and count up

export class NoRoleSetError extends ErrorWithCode {
  constructor(...args) {
    super('Cannot login as user has no role assigned!', 872, ...args)
    this.name = this.constructor.name
  }
}

export class MultipleRolesSetError extends ErrorWithCode {
  constructor(...args) {
    super('Cannot login as user has multiple roles assigned!', 873, ...args)
    this.name = this.constructor.name
  }
}

export class NoClientUrlSetError extends ErrorWithCode {
  constructor(...args) {
    super(
      'Cannot login as user is not associated with a client_url! client_url should be set for users company.',
      874,
      ...args,
    )
    this.name = this.constructor.name
  }
}

export class MultipleClientUrlsSetError extends ErrorWithCode {
  constructor(...args) {
    super(
      `Cannot login as the user is associated with multiple client_urls! A single client_url should be set for the user's company.`,
      875,
      ...args,
    )
    this.name = this.constructor.name
  }
}

export class InvalidClientUrlError extends ErrorWithCode {
  constructor(...args) {
    super(
      'Cannot login as users client_url is not a a valid url!',
      876,
      ...args,
    )
    this.name = this.constructor.name
  }
}

export class ClientNotReachableError extends ErrorWithCode {
  constructor(...args) {
    super(
      'Cannot login as client cannot be reached under provided client_url!',
      877,
      ...args,
    )
    this.name = this.constructor.name
  }
}

export class NoCompanySetError extends ErrorWithCode {
  constructor(...args) {
    super('Cannot login as user has no company assigned!', 878, ...args)
    this.name = this.constructor.name
  }
}

export class MultipleCompaniesSetError extends ErrorWithCode {
  constructor(...args) {
    super('Cannot login as user has multiple companies assigned!', 879, ...args)
    this.name = this.constructor.name
  }
}
