import { evoConsolidation, evoKeycloak, realm } from '$api'
import { fetchCompanyData, putBookedService, postOrder } from '$api/evoAPIs'
import { AuthContext } from '$context/AuthContextProvider'
import { convertStatusInfoToStep, convertStepToStatusInfo } from '$utils'
import { BarChartOutlined } from '@ant-design/icons'
import { UilEnvelope, UilPhone, UilStopwatch } from '@iconscout/react-unicons'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Avatar, Badge, Button, Col, Divider, Row, message } from 'antd'
import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Order from './Order'
import Progresssteps from './Progresssteps'
import { ExpandableTableCard, LabelText } from './styledComponents'

const ExpandedRow = ({ record }) => {
  const [finished, setFinished] = useState(false)
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [orderOfferText, setOrderOfferText] = useState(
    record?.price_information,
  )
  const [orderRejectText, setOrderRejectText] = useState(
    record?.price_information,
  )
  const [acceptOrder, setAcceptOrder] = useState(true)
  const [current, setCurrent] = useState(0)
  const [contactDetails, setContactDetails] = useState([])
  const authContext = useContext(AuthContext)
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const next = () => {
    setCurrent(current + 1)
    updateBookedserviceStatus.mutate(convertStepToStatusInfo(current + 1))
  }
  const prev = () => {
    setCurrent(current - 1)
    updateBookedserviceStatus.mutate(convertStepToStatusInfo(current - 1))
  }
  const steps = [
    {
      title: t('bookedservices.status.placed'),
      description: t('bookedservices.order_placed_by_customer'),
    },
    {
      title: t('bookedservices.status.in_progress'),
      description: t('bookedservices.order_is_being_processed'),
    },
    {
      title: t('bookedservices.status.delivery'),
      description: t('bookedservices.order_in_delivery'),
    },
  ]

  const updateBookedserviceStatus = useMutation(
    (status) => {
      return evoConsolidation.put(
        putBookedService + record.id + '/status-info?status_info=' + status,
        {
          [record.part.db_id_client]: status,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['allBookedServices'])
        message.success('Progress modification changed')
      },
      onError: (err) => {
        message.error(err.response.status + ', not working')
      },
    },
  )

  const patchOrderPartOfferText = useMutation(
    (text) => {
      if (!record.id) {
        console.error('Record is missing id')
        return false
      }
      return evoConsolidation.patch(
        postOrder + record.id + '/price-information',
        acceptOrder ? text : '',
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['allOrderParts'])
        message.success('Offer placed')
      },
      onError: (err) => {
        message.error(err.response.status + ', not working')
      },
    },
  )

  const patchOrderPartRejectText = useMutation(
    (text) => {
      if (!record.id) {
        console.error('Record is missing id')
        return false
      }
      return evoConsolidation.patch(
        postOrder + record.id + '/provider-rejection-reason',
        acceptOrder ? text : '',
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['allOrderParts'])
        message.success('Order rejected')
      },
      onError: (err) => {
        message.error(err.response.status + ', not working')
      },
    },
  )

  useEffect(() => {
    if (record.part.db_id_client && record?.status_info) {
      setCurrent(convertStatusInfoToStep(record.status_info))
    }
  }, [record.part, record?.status_info])

  useEffect(() => {
    // when Feasibility Check, ensure that the user cannot step back
    if (record.feasible_printers) {
      setFinished(true)
    }
  }, [record.feasible_printers])

  useEffect(() => {
    // get company phone and email
    evoKeycloak
      .get(
        `/admin/realms/${realm}/groups?search=${record.company.replace(/^\//, '')}`,
      )
      .then((response) => {
        const queryKey = [null, response.data[0].id]
        const companyData = fetchCompanyData({ queryKey })
        companyData.then((response) => {
          setContactDetails([
            response.attributes.phone,
            response.attributes.email[0],
          ])
        })
      })
      .catch((error) => {
        // handle error
        console.error(error)
      })
  }, [record.company])

  const onFinishActions = () => {
    setCurrent(current + 1)
    setFinished(true)
    message.success(t('bookedservices.processing_complete'))
  }

  const onFinish = () => {
    if (isFileUploaded) {
      onFinishActions()
      updateBookedserviceStatus.mutate(convertStepToStatusInfo(3))
    } else if (orderOfferText && acceptOrder === true) {
      onFinishActions()
      updateBookedserviceStatus.mutate(convertStepToStatusInfo(3))
      patchOrderPartOfferText.mutate(orderOfferText)
    } else if (orderRejectText && acceptOrder === false) {
      onFinishActions()
      updateBookedserviceStatus.mutate(convertStepToStatusInfo(3))
      patchOrderPartRejectText.mutate(orderRejectText)
    } else {
      message.error(t('bookedservices.processing_complete_error'))
    }
  }

  return (
    <>
      {record?.service_name === 'Digital Parts Report' ? (
        <Badge.Ribbon
          text="demo"
          color="#CCC"
          style={{ margin: '10px -15px' }}
        ></Badge.Ribbon>
      ) : null}
      <ExpandableTableCard>
        <Row>
          <Col
            span={9}
            className="formcolumn"
            style={{ padding: '0 30px 0 0' }}
          >
            <div style={{ display: 'flex' }}>
              <div style={{ margin: '-10px 10px 0 -8px' }}>
                <Avatar
                  icon={<BarChartOutlined />}
                  style={{
                    margin: '3px 3px 5px',
                    border: '5px solid white',
                    width: '52px',
                    height: '52px',
                    fontSize: '22px',
                    lineHeight: '43px',
                  }}
                />
              </div>
              <div>
                <LabelText style={{ marginBottom: '2px' }}>
                  {t('Ordered by')}:
                </LabelText>
                <p>{record.company.replace(/^\//, '')}</p>
              </div>
            </div>
          </Col>
          <Col span={10}>
            <div>
              <div>
                <UilPhone style={{ margin: '0 18px -8px 0', width: '15px' }} />{' '}
                {contactDetails[0]}
              </div>
              <div>
                <UilEnvelope
                  style={{ margin: '0 18px -8px 0', width: '15px' }}
                />{' '}
                {contactDetails[1]}
              </div>
            </div>
          </Col>
          <Col span={5}>
            <div>
              <LabelText style={{ marginBottom: '2px' }}>
                {t('bookedservices.date_of_request')}:
              </LabelText>
              <div>
                <UilStopwatch
                  style={{ margin: '0 14px -8px 0', width: '15px' }}
                />
                {dayjs(record.time_stamp).fromNow()}
              </div>
            </div>
          </Col>
          <Divider />
        </Row>
        <Row>
          <Col
            span={19}
            className="formcolumn"
            style={{ padding: '0 30px 0 0' }}
          >
            <Order
              record={record}
              setIsFileUploaded={setIsFileUploaded}
              setOrderOfferText={setOrderOfferText}
              orderOfferText={orderOfferText}
              setAcceptOrder={setAcceptOrder}
              acceptOrder={acceptOrder}
              setOrderRejectText={setOrderRejectText}
              orderRejectText={orderRejectText}
              current={current}
            />
          </Col>
          <Col span={5} className="formcolumn">
            <Progresssteps
              current={current}
              setCurrent={setCurrent}
              status={record.part_status}
              steps={steps}
            />
            {current > 0 && (
              <Button
                style={{
                  margin: '0 8px',
                }}
                onClick={() => prev()}
                disabled={finished}
              >
                {t('button.back')}
              </Button>
            )}
            {current === 0 && (
              <Button
                type="primary"
                onClick={() => {
                  next()
                  message.success(
                    `Accepted by ${authContext.user.firstName} ${
                      authContext.user.lastName
                    } at ${new Date().toUTCString()}`,
                  )
                }}
              >
                {t('button.accept_and_continue')}
              </Button>
            )}
            {current > 0 && current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                {t('button.next')}
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button disabled={finished} type="primary" onClick={onFinish}>
                {t('button.done')}
              </Button>
            )}
          </Col>
        </Row>
      </ExpandableTableCard>
    </>
  )
}

export default ExpandedRow
