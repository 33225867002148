import { Col, Divider, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledAddress = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  gap: 20px;
`

const OrderAddressView = ({ billingAddress, deliveryAddress }) => {
  const { t } = useTranslation()
  const addressLabels = (
    <div style={{ textAlign: 'right', opacity: '0.6' }}>
      <p>
        {t('address.company')}:
        <br />
        {t('address.lines')}:
      </p>
      <p>
        {t('address.city')}, {t('address.postcode')}:
        <br />
        {t('address.state_placeholder')}:
        <br />
        {t('address.country')}:
      </p>
    </div>
  )

  return (
    <>
      <Row>
        <Col span={12}>
          <h4>{t('service.order_part.order_adress.delivery_address')}</h4>
          <br />
          <StyledAddress>
            {addressLabels}
            <div>
              <p>
                {billingAddress?.name}
                <br />
                {billingAddress?.address_line_1}{' '}
                {billingAddress?.address_line_2}
              </p>
              <p>
                {billingAddress?.postcode} {billingAddress?.city}
                <br />
                {billingAddress?.state}
                <br />
                {billingAddress?.country}
              </p>
            </div>
          </StyledAddress>
          <Divider type="vertical" />
        </Col>

        <Col span={12}>
          <h4>{t('service.order_part.order_adress.billing_address')}</h4>
          <br />
          <StyledAddress>
            {addressLabels}
            <div>
              <p>
                {deliveryAddress?.name}
                <br />
                {deliveryAddress?.address_line_1}{' '}
                {deliveryAddress?.address_line_2}
              </p>
              <p>
                {deliveryAddress?.postcode} {deliveryAddress?.city}
                <br />
                {deliveryAddress?.state}
                <br />
                {deliveryAddress?.country}
              </p>
            </div>
          </StyledAddress>
        </Col>
      </Row>
    </>
  )
}

export default OrderAddressView
