import { useState, useContext, useEffect } from 'react'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { StyledLayout } from '$globalStyledComponents/Layout'
import { StyledCard, StyledUserCard } from '$globalStyledComponents/Card'
import { AuthContext } from '../../../context/AuthContextProvider'
import Footer from '$components/Footer'
import Userform from './Userform'
import Userlist from './Userlist'
import Usercard from './Usercard'

const { Content } = StyledLayout

const User = () => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)

  const [newUser, setNewUser] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  useEffect(() => {
    setSelectedUser(authContext.user.id)
  }, [authContext.user.id])

  return (
    <StyledLayout className="layout">
      <Content>
        <h1 style={{ margin: '50px 0' }}>{t('User Master Data')}</h1>
        <Row>
          <Col flex="450px">
            <StyledUserCard>
              <Usercard />
            </StyledUserCard>
            {authContext.isSuperadmin() && (
              <StyledCard>
                <Userlist
                  className="contentcard"
                  newUser={newUser}
                  setNewUser={setNewUser}
                  selectedUserId={selectedUser}
                  setSelectedUserId={setSelectedUser}
                />
              </StyledCard>
            )}
          </Col>
          <Col flex="auto">
            <div>
              <Userform
                newUser={newUser}
                setNewUser={setNewUser}
                userId={selectedUser}
                setUserId={setSelectedUser}
              />
            </div>
          </Col>
        </Row>
      </Content>
      <Footer />
    </StyledLayout>
  )
}

export default User
