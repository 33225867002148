import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { fetchAllFCServices } from '$api/evoAPIs'
import { App } from 'antd'
import { updateStatusAttributes } from '$utils'
import { useRefetchTimer } from '$context/RefetchTimerProvider'
import { evoClient, putPart } from '$api/client'

export const FcUpdateParts = () => {
  const queryClient = useQueryClient()
  const { stopRefetch } = useRefetchTimer()
  const { message, notification } = App.useApp()

  let arrOfNotUpdatedIds
  try {
    arrOfNotUpdatedIds = JSON.parse(localStorage.getItem('notupdatedfc'))
  } catch {
    arrOfNotUpdatedIds = []
  }

  const updateClientPartStatus = useMutation(
    async (partsToUpdate) => {
      // copy necessary part attributes for update
      const {
        db_id_client,
        name,
        attributes,
        float_attributes,
        status_attributes,
      } = partsToUpdate.part

      let clientPart = {
        name,
        dwh_materials: [],
        // deep copy lists
        attributes: attributes.map((attr) => ({ ...attr })),
        float_attributes: float_attributes.map((attr) => ({ ...attr })),
        status_attributes: status_attributes.map((attr) => ({ ...attr })),
      }

      // if "Feasibility Check" was feasible change status in part
      if (partsToUpdate.feasible_printers.length > 0) {
        clientPart = updateStatusAttributes(
          clientPart,
          'attributes',
          'Feasibility Check',
          'true',
        )
        clientPart = updateStatusAttributes(
          clientPart,
          'attributes',
          'Technical Suitability',
          'true',
        )
        clientPart = updateStatusAttributes(
          clientPart,
          'status_attributes',
          'Feasibility Check',
          '1',
        )
      } else {
        clientPart = updateStatusAttributes(
          clientPart,
          'attributes',
          'Feasibility Check',
          'false',
        )
        clientPart = updateStatusAttributes(
          clientPart,
          'status_attributes',
          'Feasibility Check',
          '1',
        )
      }

      //updateStatusAttributes(part, "attributes", "Feasibility Check", true)
      return evoClient.put(putPart + db_id_client, clientPart, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['parts'])
        queryClient.invalidateQueries(['bookedServices'])
        queryClient.invalidateQueries(['orderPartsLists'])
        queryClient.invalidateQueries(['allparts'])

        notification.success({
          message: 'Hinweis',
          description:
            "Alle 'Feasibility Checks' sind fertig. Die betreffenden Parts wurden aktualisiert",
          onClick: () => {},
          placement: 'top',
        })
        localStorage.setItem('notupdatedfc', '')
        stopRefetch()
      },
      onError: (err) => {
        message.error(err.response.status + ', Fehler')
      },
    },
  )

  useQuery(['feasibilitycheck'], fetchAllFCServices, {
    //enabled: typeof bsData !== "undefined",

    onSuccess: (data) => {
      // generates one bookedservice-like object from every part in these 3 arrays
      if (arrOfNotUpdatedIds && arrOfNotUpdatedIds.length > 0) {
        for (let i of data) {
          for (let localid of arrOfNotUpdatedIds) {
            if (i.id === localid) {
              updateClientPartStatus.mutate(i)
            }
          }
        }
      }
    },
  })

  useEffect(() => {
    if (!arrOfNotUpdatedIds || arrOfNotUpdatedIds.length === 0) {
      stopRefetch()
    }
  })
}
