import * as React from 'react'
import { Route, Routes as RouterDomRoutes } from 'react-router-dom'
import HeaderRoute from './components/HeaderRoute'
import ProtectedRoute from '$components/ProtectedRoute'
import Marketplace from './pages/serviceuser/Marketplace'
import Bookservice from './pages/serviceuser/Marketplace/Bookservice'
import Login from './pages/shared/Login'
import Monitor from './pages/shared/Monitor'
import Mymaterials from './pages/serviceuser/Mymaterials'
import Myservices from './pages/serviceuser/Myservices'
import Myorders from '$pages/serviceuser/Myorders'
import Partsmasterdata from './pages/serviceuser/Partsmasterdata'
import Orderedservices from './pages/serviceprovider/Orderedservices'
import Myprinters from './pages/serviceprovider/Myprinters'
import ReceivedRequests from './pages/serviceprovider/ReceivedRequests'
import Supply from './pages/shared/Supply'
import User from './pages/shared/User'
import Company from './pages/shared/Company'
import Imprint from './pages/shared/Imprint'
import DataPrivacy from './pages/shared/DataPrivacy'
import LegalInformation from './pages/shared/LegalInformation'
import GeneralTerms from './pages/shared/GeneralTerms'
import NotFoundPage from './pages/shared/404'
import { AuthContext } from './context/AuthContextProvider'

const Routes = () => {
  const [current, setCurrent] = React.useState('marketplace')
  const authContext = React.useContext(AuthContext)
  const intendedPath = window.location.pathname

  if (authContext.isServiceProvider()) {
    return (
      <RouterDomRoutes>
        <Route path="/login" element={<Login />} />
        <Route
          element={
            <HeaderRoute currentRoute={current} setCurrentRoute={setCurrent} />
          }
        >
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/data-privacy" element={<DataPrivacy />} />
          <Route path="/legal-information" element={<LegalInformation />} />
        </Route>
        <Route
          element={
            <ProtectedRoute
              current={current}
              setCurrent={setCurrent}
              intendedPath={intendedPath}
            />
          }
        >
          <Route path="/" element={<Orderedservices />} />
          <Route path="/user" element={<User />} />
          <Route path="/company" element={<Company />} />
          <Route path="/orderedservices" element={<Orderedservices />} />
          <Route path="/myprinters" element={<Myprinters />} />
          <Route path="/receivedrequests" element={<ReceivedRequests />} />
          <Route
            path="*"
            element={
              <NotFoundPage linkto="orderedservices" setCurrent={setCurrent} />
            }
          />
        </Route>
      </RouterDomRoutes>
    )
  } else if (authContext.isServiceUser) {
    return (
      <>
        <RouterDomRoutes>
          <Route path="/login" element={<Login />} />
          <Route
            element={
              <HeaderRoute
                currentRoute={current}
                setCurrentRoute={setCurrent}
              />
            }
          >
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/data-privacy" element={<DataPrivacy />} />
            <Route path="/legal-information" element={<LegalInformation />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                current={current}
                setCurrent={setCurrent}
                intendedPath={intendedPath}
              />
            }
          >
            <Route path="/" element={<Marketplace setCurrent={setCurrent} />} />
            <Route
              path="/marketplace"
              element={<Marketplace setCurrent={setCurrent} />}
            >
              <Route index element={<Marketplace setCurrent={setCurrent} />} />
              <Route path="orderservice" element={<Bookservice />} />
              {/* <Route path="account" element={<Account />} /> */}
            </Route>
            <Route path="/partsmasterdata" element={<Partsmasterdata />} />
            <Route path="/supply" element={<Supply />} />
            <Route path="/monitor" element={<Monitor />} />
            <Route path="/mymaterials" element={<Mymaterials />} />
            <Route path="/myservices" element={<Myservices />} />
            <Route path="/myorders" element={<Myorders />} />
            <Route path="/user" element={<User />} />
            <Route path="/company" element={<Company />} />
            <Route path="/general-terms" element={<GeneralTerms />} />
            <Route
              path="*"
              element={
                <NotFoundPage linkto="marketplace" setCurrent={setCurrent} />
              }
            />
          </Route>
        </RouterDomRoutes>
      </>
    )
  }
}

export default Routes
